<form [formGroup]="detailsFormGroup">
  <mat-form-field class="chillz-form-field" formGroupName="birthday">
    <mat-label>Date of Birth</mat-label>
    <input
      matInput
      [matDatepicker]="dp"
      formControlName="dateObj"
      [max]="birthdayValidRange.max.toDate()"
      [min]="birthdayValidRange.min.toDate()"
    />
    <mat-datepicker-toggle matSuffix [for]="dp">
      <mat-icon matDatepickerToggleIcon svgIcon="chillz-calendar-linear" class="stroke-black-300"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker
      [panelClass]="'chillz-datepicker'"
      [startView]="detailsFormGroup.get('birthday').get('dateObj').value ? 'month' : 'multi-year'"
      [startAt]="detailsFormGroup.get('birthday').get('dateObj').value || '2000-01-01'"
      #dp
    ></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="chillz-form-field">
    <mat-label>Gender</mat-label>
    <mat-select panelClass="chillz-select-panel" disableOptionCentering="true" formControlName="gender">
      <mat-option></mat-option>
      <mat-option value="male">Male</mat-option>
      <mat-option value="female">Female</mat-option>
      <mat-option value="other">Other</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container formGroupName="hometown">
    <mat-form-field class="chillz-form-field">
      <mat-label>Country</mat-label>
      <mat-select panelClass="chillz-select-panel" disableOptionCentering="true" formControlName="country">
        <mat-option></mat-option>
        <mat-option [value]="country.code" *ngFor="let country of countries">{{ country.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="chillz-form-field" *ngIf="detailsFormGroup.get('hometown').get('country').value === 'US'">
      <mat-label>State</mat-label>
      <mat-select panelClass="chillz-select-panel" disableOptionCentering="true" formControlName="state">
        <mat-option></mat-option>
        <mat-option [value]="state.abbreviation" *ngFor="let state of states">{{ state.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="chillz-form-field">
      <mat-label>City</mat-label>
      <input matInput name="city" type="text" formControlName="city" />
    </mat-form-field>
  </ng-container>

  <div class="actions">
    <chillz-base-button
      [label]="{ en: 'PROFILE.SAVE' | translate, he: 'PROFILE.SAVE' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      [disabled]="detailsFormGroup.invalid || detailsFormGroup.pristine"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-base-button>
  </div>
</form>
