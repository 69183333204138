export const locale = {
  lang: 'he',
  data: {
    SHARED_COMPONENTS: {
      PHONE_NUMBER_INPUT: {
        SEARCH: 'חיפוש',
        PHONE: 'טלפון',
      },
      RESET_PASSWORD: {
        YOUR_PASSWORD_UPDATED: 'Password updated',
        YOUR_PASSWORD_UPDATED_DESCRIPTION:
          'Your password has been successfully changed. Use your new password to sign in',
        BACK_TO_LOGIN: 'Back to sign in',
        RESET_YOUR_PASSWORD: 'Reset your password',
        TYPE_YOUR_NEW_PASSWORD: 'Type your new password',
        RESET_MY_PASSWORD: 'Reset my password',
      },
      PASSWORD_CREATION: {
        CONFIRM_CURRENT_PASSWORD: 'Confirm current password',
        YOUR_CURRENT_PASSWORD_REQUIRED: ' Your current password is required',
        NEW_PASSWORD: 'New password',
        PASSWORD_REQUIRED: 'Password is required',
        REPEAT_NEW_PASSWORD: 'Repeat new password',
        PASSWORD_CONFIRMATION_REQUIRED: 'Password confirmation is required',
        PASSWORD_MUST_MATCH: 'Passwords must match',

        ONE_LOWERCASE_CHARACTER: 'One lowercase character',
        ONE_UPPERCASE_CHARACTER: 'One uppercase character',
        MINIMUM_CHARACTERS: 'At least 8 characters',
        ONE_DIGIT: 'One digit',
      },
      VERIFICATION: {
        CHECK_YOUR_PHONE: 'Check your phone for a code',
        CHECK_YOUR_INBOX: 'Check your inbox for a code',
        CODE_SENT:
          ' We’ve sent a {{length}}-character code to {{destination}}. The code expires shortly, so please enter it soon.',
        DIDNT_RECEIVED_A_CODE: 'I didn’t receive a code.',
        RESEND: 'Resend',
        SUBMIT: 'Submit',
        BACK: 'Back',
      },
      TICKET_PORTRAIT_POSTER_FIT: {
        EVENT: 'Event',
        TICKET_TYPE: 'Ticket type',
        DATE_AND_TIME: 'Date & Time',
        WHERE: 'Where',
        PRICE: 'Price',
        CANCELED: 'Canceled',
        ADD_TO_WALLET: 'Add to Apple Wallet',
      },
    },
  },
};
