import { Location } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { TicketTypesService } from '../../../main/entity/ticket-types.service';
import { Order } from '../../../main/orders/order/order.model';
import { OrdersService } from '../../../main/orders/orders.service';
import { BundleOffer } from '../../models/bundle-offer.model';
import { TrackingService } from '../../tracking.service';
import { locale as english } from './i18n/en';
import { locale as hebrew } from './i18n/he';
import { IOrderPreparationState, OrderPreparationService } from './order-preparation.service';


@Component({
  selector: 'app-order-preparation',
  templateUrl: './order-preparation.component.html',
  styleUrls: [ './order-preparation.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class OrderPreparationComponent implements OnInit, OnDestroy {
  state: IOrderPreparationState;
  itemsAdded: number;
  order: Order;
  minimized = false;
  bundle: BundleOffer;
  startChangeTicketQuantity = this._orderPreparationService.startChangeTicketQuantity;

  @Output() minimize: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _unsubscribeAll = new Subject<any>();

  constructor (
    public localizationService: AppLocalizationService,
    private _orderPreparationService: OrderPreparationService,
    private translationLoader: FuseTranslationLoaderService,
    private _ordersService: OrdersService,
    private _appService: AppService,
    private _ticketTypesService: TicketTypesService,
    private _trackingService: TrackingService,
    private _location: Location,
    private _router: Router
  ) {}

  ngOnInit (): void {
    this._orderPreparationService.onStateChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((s) => {
      this.state = s;
    });

    this._orderPreparationService.onOrderChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((order) => {
      this.order = order;
      this.onMinimize(false);
    });

    this._ordersService.onOrderChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((order) => {
        this._orderPreparationService.setOrder(order);
      });
    this.translationLoader.loadTranslations(english, hebrew);

    this._orderPreparationService.onItemsCountChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((count) => (this.itemsAdded = count));
  }

  onCountdownFinished (): void {
    this._orderPreparationService.finishCountdown();
  }

  onGoToCheckout (): void {
    if (this.state.disabled || this.state.loading || this.state.creating || !this.order) {
      return;
    }

    this._appService.showAnimatedBackground();

    this._trackingService.triggerEvent('initiate-checkout', {
      currency: this.order.currency,
      value: this.order.total || this.order.subtotal,
      items: this.order.items
        .filter((x) => x.quantity)
        .map((x) => ({
          item: x.ticketType || x.product,
          quantity: x.quantity,
        })),
    });

    this._router.navigate([ '/', 'orders', this.order?.id || 'new' ], { queryParamsHandling: 'merge' });
  }

  isAtEntryPoint (): boolean {
    if (!this.order?.entryPoint) {
      return false;
    }

    return this._location.isCurrentPathEqualTo(this.order.entryPoint);
  }

  onMinimize (minimized: boolean): void {
    this.minimized = minimized;
    this.minimize.emit(this.minimized);
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
