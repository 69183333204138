<div class="profile-page" fadeInAnimation *ngIf="profile">
  <h2 class="profile-page-title">
    {{ 'PROFILE.UPDATE_MY_PROFILE' | translate: { brandName: platform.brandName['en'] || '' } }}
  </h2>

  <chillz-card>
    <chillz-card-section>
      <chillz-card-header (click)="onOpenDialog('name', { en: translateService.instant('PROFILE.NAME') })">
        {{ 'PROFILE.NAME' | translate }}
      </chillz-card-header>
      <chillz-card-content>
        <app-profile-name></app-profile-name>
      </chillz-card-content>
    </chillz-card-section>
  </chillz-card>

  <chillz-card>
    <chillz-card-section>
      <chillz-card-header
        (click)="onOpenDialog('general-details', { en: translateService.instant('PROFILE.DETAILS') })"
      >
        {{ 'PROFILE.DETAILS' | translate }}
      </chillz-card-header>
      <chillz-card-content>
        <app-profile-general-details></app-profile-general-details>
      </chillz-card-content>
    </chillz-card-section>
  </chillz-card>

  <chillz-card>
    <chillz-card-section>
      <chillz-card-header
        (click)="onOpenDialog('phone-number', { en: translateService.instant('PROFILE.PHONE_NUMBER') })"
      >
        {{ 'PROFILE.PHONE_NUMBER' | translate }}
      </chillz-card-header>
      <chillz-card-content>
        <app-profile-phone-number></app-profile-phone-number>
      </chillz-card-content>
    </chillz-card-section>
  </chillz-card>

  <chillz-card>
    <chillz-card-section>
      <chillz-card-header (click)="onOpenDialog('email', { en: translateService.instant('PROFILE.EMAIL') })">
        {{ 'PROFILE.EMAIL' | translate }}
      </chillz-card-header>
      <chillz-card-content>
        <app-profile-email></app-profile-email>
      </chillz-card-content>
    </chillz-card-section>
  </chillz-card>

  <chillz-card>
    <chillz-card-section>
      <chillz-card-header
        (click)="onOpenDialog('social-media', { en: translateService.instant('PROFILE.SOCIAL_MEDIA') })"
      >
        {{ 'PROFILE.SOCIAL_MEDIA' | translate }}
      </chillz-card-header>
      <chillz-card-content>
        <app-profile-social-media></app-profile-social-media>
      </chillz-card-content>
    </chillz-card-section>
  </chillz-card>

  <chillz-card>
    <chillz-card-section>
      <chillz-card-header
        (click)="onOpenDialog('password', { en: translateService.instant('PROFILE.SET_A_PASSWORD') })"
      >
        {{ 'PROFILE.PASSWORD' | translate }}
      </chillz-card-header>
      <chillz-card-content>
        <div class="password-section">
          <chillz-base-button
            [variant]="'primary'"
            [size]="'medium'"
            [icon]="'chillz-password'"
            (clicked)="onOpenDialog('password', { en: translateService.instant('PROFILE.SET_A_PASSWORD') })"
            [label]="{
              en:
                (profile.hasPassword ? ('PROFILE.RESET' | translate) : ('PROFILE.SET_A' | translate)) +
                ' ' +
                ('PROFILE.PASSWORD' | translate)
            }"
          ></chillz-base-button>
          <div class="B6-Regular black-300" *ngIf="profile.lastPasswordChange">
            {{ 'PROFILE.LAST_CHANGED' | translate }} {{ profile.lastPasswordChange?.toString() | timeAgo }}
          </div>
        </div>
      </chillz-card-content>
    </chillz-card-section>
  </chillz-card>
</div>
