import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ParsedPhoneNumber } from 'awesome-phonenumber';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { VerificationService } from '../../../auth/verification/verification.service';
import { Platform } from '../../../shared/models/platform.model';
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-profile-phone-number',
  templateUrl: './profile-phone-number.component.html',
  styleUrls: [ './profile-phone-number.component.scss', '../profile-form.scss' ],
})
export class ProfilePhoneNumberComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  phoneError: string;
  phoneVerificationToken: string;
  phoneNumberFormGroup: FormGroup;
  disableSubmitCodeButton = true;

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  parsedPhoneNumber: ParsedPhoneNumber;

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _formBuilder: FormBuilder,
    private _verificationService: VerificationService
  ) {}

  ngOnInit (): void {
    this.phoneNumberFormGroup = this._formBuilder.group({
      countryCode: [],
      phoneNumber: [],
    });

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        this.phoneNumberFormGroup.patchValue(this.profile, { emitEvent: false });
        this.phoneNumberFormGroup.markAsPristine();
      });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(5000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');

    this._verificationService
      .initVerification(this.phoneNumberFormGroup.getRawValue())
      .then((x) => {
        this.phoneVerificationToken = x;
        this.phoneError = undefined;
      })
      .catch((err) => {
        this.status$.emit(null);
        this.phoneError = err?.error?.error;
      })
      .finally(() => {
        this.phoneNumberFormGroup.markAsPristine();
      });
  }

  onPhoneChanged ($event: ParsedPhoneNumber): void {
    this.phoneNumberFormGroup.get('phoneNumber').setValue($event?.number?.significant, { emitEvent: false });
    this.phoneNumberFormGroup.get('countryCode').setValue($event?.countryCode?.toString(), { emitEvent: false });

    this.parsedPhoneNumber = $event;

    if (
      $event.number?.significant === this.profile.phoneNumber &&
      $event.countryCode?.toString() === this.profile.countryCode
    ) {
      this.phoneNumberFormGroup.markAsPristine();
    } else {
      this.phoneNumberFormGroup.markAsDirty();
      if (!$event.valid) {
        this.phoneNumberFormGroup.setErrors({ invalid: true });
      } else {
        this.phoneNumberFormGroup.setErrors({ invalid: null });
        this.phoneNumberFormGroup.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  setPhoneError (error: string): void {
    this.phoneError = error;
    this.updateSubmitButtonState(error);
  }

  private updateSubmitButtonState (error: string): void {
    const errorMessages = [ 'Max check attempts reached', 'Max send attempts reached' ].map(this.normalizeString);

    const normalizedError = this.normalizeString(error);

    this.disableSubmitCodeButton = errorMessages.some((msg) => normalizedError.includes(msg));
  }

  private normalizeString (str: string): string {
    return str.replace(/[,.-]/g, '').trim().toLowerCase();
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
