<ng-container *ngIf="!thumbPlaceholder?.picture && thumbPlaceholder?.symbol">
  <chillz-skeleton-block
    [style.border-radius]="borderRadius"
    *ngIf="!thumbPlaceholderSymbolLoaded"
  ></chillz-skeleton-block>
  <div
    class="default-poster"
    [style.background-color]="thumbPlaceholder?.backgroundColor"
    [style.border-radius]="borderRadius"
    [chillzOpacityAnimation]="thumbPlaceholderSymbolLoaded"
  >
    <img
      (load)="thumbPlaceholderSymbolLoad()"
      [src]="thumbPlaceholder?.symbol"
      class="thumb-placeholder-symbol"
      alt=""
    />
  </div>
</ng-container>
<ng-container *ngIf="thumbPlaceholder?.picture">
  <img
    loading="lazy"
    class="thumb-default-image"
    [style.border-radius]="borderRadius"
    (load)="thumbPlaceholderPictureLoad()"
    [chillzOpacityAnimation]="thumbPlaceholderPictureLoaded"
    [src]="thumbPlaceholder?.picture"
    alt=""
  />
  <chillz-skeleton-block
    [style.border-radius]="borderRadius"
    *ngIf="!thumbPlaceholderPictureLoaded"
  ></chillz-skeleton-block>
</ng-container>
