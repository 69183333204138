import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { LocalStorage } from '../../../../@fuse/services/storage/local-storage';
import { AppService } from '../../../app.service';
import { Entity } from '../../../main/entity/entity.model';
import { TicketTypesService } from '../../../main/entity/ticket-types.service';
import { Order } from '../../../main/orders/order/order.model';
import { OrdersService } from '../../../main/orders/orders.service';
import { BundleOffer } from '../../models/bundle-offer.model';
import { GeneralProduct } from '../../models/general-product.model';
import { OrderItem } from '../../models/order-item.model';
import { TicketType } from '../../models/ticket-type.model';
import { TrackingService } from '../../tracking.service';


export interface IOrderPreparationState {
  creating: boolean;
  disabled: boolean;
  hidden: boolean;
  loading?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrderPreparationService {
  currentEntity: Entity;
  activePromoCode: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  selectedItems: { item: OrderItem; quantity }[] = [];
  onSelectedItemsChanged: BehaviorSubject<{ item: OrderItem; quantity }[]> = new BehaviorSubject<
    { item: OrderItem; quantity }[]
  >(undefined);

  itemsCount: number;
  onItemsCountChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  order: Order;
  onOrderChanged: BehaviorSubject<Order> = new BehaviorSubject<Order>(undefined);

  bundleOffer: BundleOffer;
  onBundleOfferChanged: BehaviorSubject<BundleOffer> = new BehaviorSubject<BundleOffer>(undefined);

  state: IOrderPreparationState = { disabled: true, creating: false, hidden: true };
  onStateChanged: BehaviorSubject<IOrderPreparationState> = new BehaviorSubject<IOrderPreparationState>(this.state);

  queryPromoCodes = [];
  startChangeTicketQuantity = new BehaviorSubject<boolean>(false);

  constructor (
    private _ordersService: OrdersService,
    private _ticketTypesService: TicketTypesService,
    private _appService: AppService,
    private _localStorage: LocalStorage,
    private _trackingService: TrackingService,
    private _location: Location,
    private _route: ActivatedRoute
  ) {
    this.activePromoCode.subscribe((x) => console.log('ActivePromoCode', x));
  }

  private startLoading (): void {
    this.state.loading = true;
    this.onStateChanged.next(this.state);
  }

  private stopLoading (): void {
    this.state.loading = false;
    this.onStateChanged.next(this.state);
  }

  updateSelectedItems (items: { item: OrderItem; quantity }[] = [], eventParticipation?: Entity): void {
    if (this.state.loading) {
      return;
    }

    this.startLoading();

    if (
      this.order &&
      (this.currentEntity?._id !== this.order?.venue?._id ||
        this.order?.eventParticipation?._id !== eventParticipation?._id)
    ) {
      this.reset();
    }

    let updated = false;
    for (const item of items) {
      const existing = this.selectedItems.find((x) => x.item._id === item.item._id);
      if (item.quantity === null) {
        item.quantity = existing ? existing.quantity : 0;
      }

      if (existing) {
        if (existing.quantity !== item.quantity) {
          updated = true;
        }

        existing.quantity = item.quantity;
      } else {
        if (item.quantity > 0) {
          updated = true;
        }

        this.selectedItems.push(item);
      }
    }
    this.onSelectedItemsChanged.next(this.selectedItems);

    if (updated) {
      const convertedItems = [];
      this.selectedItems.forEach((s) => {
        const converted: any = {
          quantity: s.quantity,
        };

        if (s.item instanceof TicketType) {
          converted.ticketType = s.item as TicketType;
        }

        if (s.item instanceof GeneralProduct) {
          converted.product = s.item as GeneralProduct;
        }

        convertedItems.push(converted);
      });

      this.onStateChanged.next(this.state);
      if (!this.order) {
        this.state.creating = true;
        this.onStateChanged.next(this.state);
        this._ordersService
          .makeOrder(
            this.currentEntity,
            convertedItems,
            false,
            undefined,
            this.bundleOffer,
            this._location.path(),
            eventParticipation
          )
          .then((order) => {
            this.order = order;
            this.onOrderChanged.next(this.order);
            this._trackingService.triggerEvent('add-to-cart', {
              currency: this.order.currency,
              value: this.order.total || this.order.subtotal,
              items: this.order.items
                .filter((x) => x.quantity)
                .map((x) => ({
                  item: x.ticketType || x.product,
                  quantity: x.quantity,
                })),
            });
          })
          .catch((error: HttpErrorResponse) => {
            this.reset();
            this.handleAvailabilityChangedError(error);
          })
          .finally(() => {
            this.state.creating = false;
            this.stopLoading();
            this.onStateChanged.next(this.state);
          });
      } else if (this.order) {
        if (!this.state.loading) {
          this.startLoading();
        }

        this._ordersService
          .updateItems(convertedItems)
          .then((order) => {
            this.order = order;
            console.log(this.order);
            this.onItemsCountChanged.next(this.order.items.reduce((a, b) => a + b.quantity, 0));
            this.state.disabled = this.itemsCount === 0;
            this.stopLoading();
            this.onOrderChanged.next(this.order);
            this.onStateChanged.next(this.state);
            console.log(this.order);

            this._trackingService.triggerEvent('add-to-cart', {
              currency: this.order.currency,
              value: this.order.total || this.order.subtotal,
              items: this.order.items
                .filter((x) => x.quantity)
                .map((x) => ({
                  item: x.ticketType || x.product,
                  quantity: x.quantity,
                })),
            });
          })
          .catch((error: HttpErrorResponse) => {
            this.setOrder(this.order);
            this.handleAvailabilityChangedError(error);
          })
          .finally(() => {
            this.stopLoading();
            this.onItemsCountChanged.next(this.order.items.reduce((a, b) => a + b.quantity, 0));
            this.state.disabled = this.itemsCount === 0;
            this.onStateChanged.next(this.state);
          });
      }
    } else {
      this.stopLoading();
    }

    this.itemsCount = this.selectedItems.reduce((a, b) => a + b.quantity, 0);
    this.state.disabled = this.itemsCount === 0;

    this.onItemsCountChanged.next(this.itemsCount);

    if (!this.state.disabled) {
      this.state.hidden = false;
    }

    this.onStateChanged.next(this.state);
  }

  setOrder (order: Order): void {
    this.order = order;
    if (order.completed) {
      return this.reset();
    }

    this.onOrderChanged.next(this.order);
    this.selectedItems = order.items.map((x) => {
      let item;

      if (x.ticketType) {
        item = new TicketType(x.ticketType);
      } else if (x.product) {
        item = new GeneralProduct(x.product);
      }

      return {
        quantity: x.quantity,
        item,
      };
    });

    this.onSelectedItemsChanged.next(this.selectedItems);
  }

  handleAvailabilityChangedError (error: HttpErrorResponse): void {
    if (error.error.error?.includes('Availability changed')) {
      // this._ticketTypesService.onTicketTypesChanged.next([]);
      // this.reset();
      this.showAvailabilityChangedModal(error.error.error?.includes('TEMP'));
      this._ticketTypesService.getTicketTypes(this.currentEntity, this.activePromoCode.value);
    }
  }

  finishCountdown (): void {
    if (this.order.ticketsSavedUntil) {
      this._ordersService.order = undefined;
      this._ordersService.onOrderChanged.next(this._ordersService.order);
      this._localStorage.removeItem('activeOrder');
      this.reset();
      this._ticketTypesService.getTicketTypes(this.currentEntity, this.activePromoCode.value).finally(() => {
        this.showCountdownFinishedDialog();
      });
    }
  }

  showCountdownFinishedDialog (): void {
    this._appService
      .confirmationDialog(
        { en: 'You snooze, you lose 👻' },
        {
          en: 'Still interested? If tickets for this event are still available, you can start a new order now. Don’t miss out!',
        },
        undefined,
        { en: 'Okay', he: 'Okay' },
        undefined,
        true
      )
      .then();
  }

  showAvailabilityChangedModal (temp = false): void {
    this._appService
      .confirmationDialog(
        temp
          ? { en: 'Check Back Shortly 👀' }
          : {
              en: 'We’re not sold out, but all spots are currently reserved by other users during their checkout process.\nCheck back in a few minutes — availability can change quickly!',
            },
        temp
          ? { en: 'Some spots are currently held by others, but keep trying — they might free up!' }
          : {
              en: 'It looks like a few of the items you picked are already gone.\nWe’ve refreshed your cart with the latest availability.',
            },
        undefined,
        { en: 'Okay', he: 'Okay' },
        undefined,
        true
      )
      .then();
  }

  fetchStoredOrder (): void {
    console.log('currentEntity', this.currentEntity);
    if (this.order?.id) {
      return;
    }

    if (!this._localStorage.getItem('activeOrder')) {
      return;
    }

    const stored: { id: string; entity: string; exp: number } = JSON.parse(this._localStorage.getItem('activeOrder'));

    if (stored.exp && new Date().getTime() > stored.exp) {
      this._ordersService.removeFromLocalStorage(stored.id);
      return;
    }

    if (!this.currentEntity || stored.entity === this.currentEntity.id) {
      this.state = { ...this.state, disabled: true, hidden: false, creating: true };
      this.onStateChanged.next(this.state);

      this._ordersService.getOrder(stored.id).then((order) => {
        console.log(order);
        if (!order?.['id']) {
          this._ordersService.removeFromLocalStorage(stored.id);
          return this.reset();
        }

        this.state = { ...this.state, disabled: false, loading: false, creating: false };
        this.onStateChanged.next(this.state);
        // this.setOrder(this._ordersService.order);
      });
    }
  }

  reset (): void {
    console.log('RESET _orderPreparationService');
    this.selectedItems = [];
    this.onSelectedItemsChanged.next(undefined);

    this.state = { disabled: true, hidden: true, creating: false };
    this.onStateChanged.next(this.state);

    this.itemsCount = 0;
    this.onItemsCountChanged.next(0);

    this.order = undefined;
    this.onOrderChanged.next(this.order);
  }
}
