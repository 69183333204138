import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { LocalStorage } from '../../../@fuse/services/storage/local-storage';
import { BundleOffer } from '../../shared/models/bundle-offer.model';
import { PromoCode } from '../../shared/models/promo-code.model';
import { SessionInfoService } from '../../shared/session-info.service';
import { TrackingService } from '../../shared/tracking.service';
import { Entity } from '../entity/entity.model';
import { Order } from './order/order.model';


@Injectable({ providedIn: 'root' })
export class OrdersService implements Resolve<any> {
  routeParams: any;
  queryParams: any;
  order: Order;
  onOrderChanged: BehaviorSubject<Order>;
  onOrderDetailsUpdated: BehaviorSubject<Order>;

  onStripeInfoChanged: BehaviorSubject<any>;

  creating;

  maxInstallment: number;
  onMaxInstallmentsChanged: BehaviorSubject<any>;

  guestList: { enabled: boolean };
  onGuestListChanged: BehaviorSubject<{ enabled: boolean }>;

  maxPaymentMethodsAllowed: number;
  onMaxPaymentMethodsChanged: BehaviorSubject<number>;
  isBrowser: boolean;

  paymentMethods: any;
  onPaymentMethodsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   */
  constructor (
    private _httpClient: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute,
    private _localStorage: LocalStorage,
    private _sessionInfoService: SessionInfoService,
    private _trackingService: TrackingService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    // Set the defaults
    this.onOrderChanged = new BehaviorSubject(undefined);
    this.onOrderDetailsUpdated = new BehaviorSubject(undefined);
    this.onStripeInfoChanged = new BehaviorSubject(undefined);
    this.onGuestListChanged = new BehaviorSubject(undefined);
    this.onPaymentMethodsChanged = new BehaviorSubject([]);
    this.onMaxInstallmentsChanged = new BehaviorSubject(1);
    this.onMaxPaymentMethodsChanged = new BehaviorSubject(1);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Resolver
   *
   */
  resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    this.routeParams = route.params;
    this.queryParams = route.queryParams;

    const promises = [];
    if (this.routeParams.id !== 'new' && !this.creating && (!this.order || this.order.id !== this.routeParams.id)) {
      this.getOrder();
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then(() => {
          resolve(false);
        })
        .catch(() => {
          this._router.navigate([ '/', 'errors', '404' ]);
          reject();
        });
    });
  }

  /**
   *
   * make an order
   */
  makeOrder (
    venue: Entity,
    items,
    seating = false,
    queryCouponCode?,
    bundleOffer?: BundleOffer,
    entryPoint?: string,
    eventParticipation?: Entity
  ): Promise<Order> {
    let ref;
    let cref;
    let couponCode = queryCouponCode;
    this.creating = true;
    if (this._sessionInfoService.sessionInfo['queryParams']) {
      ref = this._sessionInfoService.sessionInfo['queryParams']['ref'];
      cref = this._sessionInfoService.sessionInfo['queryParams']['cref'];
      couponCode = couponCode || this._sessionInfoService.sessionInfo['queryParams']['code'];
    }

    ref = ref || this.queryParams?.ref;
    couponCode = couponCode || this.queryParams?.code;

    this.order = new Order({
      venue,
      items: items.map((item) => ({
        ticketType: item.ticketType,
        product: item.product,
        quantity: item.quantity,
        subtotal:
          item.quantity *
          (item.ticketType?.price !== undefined ? item.ticketType?.price : item.generalProduct?.price || 0),
      })),
      currency: items[0].ticketType.currency,
      entryPoint,
      bundle: {
        bundleOffer,
      },
    });

    this.order.subtotal = this.order.items.reduce((a, b) => a + b.subtotal, 0);
    this.order.items = this.order.items.filter((x) => !!x.quantity);

    this.onOrderChanged.next(this.order);

    items.forEach((item) => {
      item.ticketType = item.ticketType?._id || item.ticketType;
      item.product = item.product?._id || item.product;
    });

    return new Promise((resolve, reject) => {
      this._httpClient
        .post('/orders', {
          items,
          venue: venue?._id,
          bundleOffer: bundleOffer?._id,
          entryPoint,
          seating,
          ref,
          cref,
          couponCode,
          eventParticipation: eventParticipation?._id,
          session: this._sessionInfoService.sessionId,
        })
        .subscribe(
          (response: any) => {
            this.order = new Order(response.order);
            // this.order.venue = new Venue(venue);
            this.onOrderChanged.next(this.order);
            this.maxInstallment = response.maxInstallmentsAllowed;
            this.onMaxInstallmentsChanged.next(response.maxInstallmentsAllowed);

            this.guestList = response.guestList;
            this.onGuestListChanged.next(this.guestList);

            if (this.order.stripe?.clientSecret) {
              this.onStripeInfoChanged.next(this.order.stripe);
            }

            if (this.isBrowser) {
              this.updateLocalstorage();
            }

            resolve(this.order);
          },
          reject,
          () => {
            this.creating = false;
          }
        );
    });
  }

  /**
   * Get country codes
   *
   */
  getCountryCodes (): Promise<any> {
    return new Promise((resolve) => {
      this._httpClient.get('assets/data/dial-codes.json').subscribe(
        (response: any) => {
          resolve(response);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  /**
   * Get order
   */
  getOrder (id?: string): Promise<any[]> {
    return new Promise((resolve) => {
      this._httpClient.get('/orders/' + (id || this.routeParams.id)).subscribe(
        (response: any) => {
          this.order = new Order(response.order);

          this.order.token = response.token;
          this.onOrderChanged.next(this.order);

          this.onMaxInstallmentsChanged.next(response.maxInstallmentsAllowed);
          this.onMaxPaymentMethodsChanged.next(response.maxPaymentMethodsAllowed);
          this.onGuestListChanged.next(response.guestList);

          if (response.pageIntegrations) {
            for (const pixel of response.pageIntegrations?.facebookPixel?.filter((x) => !!x) || []) {
              this._trackingService.addPixel(pixel);
            }
            for (const pixel of response.pageIntegrations?.gtag?.filter((x) => !!x) || []) {
              this._trackingService.addGtag(pixel);
            }
            for (const pixel of response.pageIntegrations?.googleAnalytics?.filter((x) => !!x) || []) {
              this._trackingService.addGA(pixel);
            }
            for (const pixel of response.pageIntegrations?.tiktok?.filter((x) => !!x) || []) {
              this._trackingService.addTiktok(pixel);
            }
            for (const pixel of response.pageIntegrations?.gtm?.filter((x) => !!x) || []) {
              this._trackingService.addGTM(pixel);
            }
            for (const pixel of response.pageIntegrations?.snapchat?.filter((x) => !!x) || []) {
              this._trackingService.addSnapchat(pixel);
            }
          }

          if (this.isBrowser) {
            this.updateLocalstorage();
          }

          if (this.isBrowser && this.order.completed && this.queryParams.redirect_status === 'succeeded') {
            this.trackPurchaseEvent();
          }

          resolve(response.order);
        },
        (error) => {
          this._router.navigate([ '/', ...(error.error.venue ? [ error.error.venue ] : []) ], {
            queryParamsHandling: 'merge',
          });
          resolve([]);
        }
      );
    });
  }

  /**
   * Get stripe info
   */
  getStripeInfo (): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/orders/' + this.routeParams.id + '/stripeInfo').subscribe(
        (response: any) => {
          if (response.stripeInfo?.clientSecret) {
            this.order.stripe = response.stripeInfo;
            this.onStripeInfoChanged.next(this.order.stripe);
            console.log(this.order);
          }

          resolve(this.order.stripe);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Update item's quantity
   */
  updateQuantity (item, quantity): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/orders/' + this.order.id + '/items/' + item, { quantity }).subscribe((response: any) => {
        this.order = new Order(response.order);
        this.onOrderChanged.next(this.order);

        if (this.isBrowser) {
          this.updateLocalstorage();
        }

        resolve(this.order);
      }, reject);
    });
  }

  /**
   * Update items
   */
  updateItems (items): Promise<any> {
    items.forEach((item) => {
      item.ticketType = item.ticketType?._id || item.ticketType;
      item.product = item.product?._id || item.product;
    });

    return new Promise((resolve, reject) => {
      this._httpClient.put('/orders/' + this.order.id + '/items', { items }).subscribe((response: any) => {
        //
        // if (!response.order.items.filter(x => x.quantity > 0).length) {
        //   this.order = undefined;
        //   this.onOrderChanged.next(undefined);
        //   return resolve(this.order);
        // }

        this.order = new Order(response.order);
        this.onOrderChanged.next(this.order);

        console.log(this.order);
        resolve(this.order);
      }, reject);
    });
  }

  /**
   * Remove Item
   */
  removeItem (item): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete('/orders/' + this.order.id + '/items/' + item).subscribe((response: any) => {
        this.order = new Order(response.order);
        this.onOrderChanged.next(this.order);

        resolve(response.venue);
      }, reject);
    });
  }

  /**
   * Invoke Activity
   */
  invokeActivity (activity, body?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.post('/orders/' + this.order.id + '/activity/' + activity, body).subscribe(() => {
        resolve([]);
      }, reject);
    });
  }

  applyPromoCode (code): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post('/orders/' + this.order.id + '/promoCodes/' + code, null).subscribe((response: any) => {
        this.order.total = response.order.total;
        this.order.taxes = response.order.taxes;
        this.order.serviceFee = response.order.serviceFee;
        this.order.additionalFee = response.order.additionalFee;
        this.order.buyerProcessingFee = response.order.buyerProcessingFee;
        this.order.accumulatedFees = response.order.accumulatedFees;
        this.order.subtotal = response.order.subtotal;

        for (const item of this.order.items) {
          const updatedItem = response.order.items.find((x) => x._id === item._id);
          if (updatedItem) {
            item.subtotal = updatedItem.subtotal;
            item.discount = updatedItem.discount;
          }
        }

        // this.order.items = response.order.items;
        this.order.discount = response.order.discount;
        this.order.promoCodes = response.order.promoCodes?.map((x) => new PromoCode(x));

        this.onOrderChanged.next(this.order);

        resolve(this.order);
      }, reject);
    });
  }

  itemsUpdated (update): void {
    this.order.items = update.items;
    this.order.total = update.total;
    this.order.coinz = update.coinz;
    this.order.seatsSavedUntil = update.seatsSavedUntil;
    this.order.error = update.error;
    this.onOrderChanged.next(this.order);
  }

  getPaymentMethods (): Promise<any> {
    const params = {};
    if (this.order.paymentService) {
      params['paymentService'] = this.order.paymentService._id;
    }

    return new Promise((resolve, reject) => {
      this._httpClient.get('/users/self/paymentMethods', { params }).subscribe(
        (response: any) => {
          this.paymentMethods = response.paymentMethods;
          this.onPaymentMethodsChanged.next(this.paymentMethods);

          resolve(this.paymentMethods);
        },
        () => {
          reject();
        }
      );
    });
  }

  selectPaymentMethod (paymentMethod): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/orders/' + this.order.id + '/paymentMethod', { paymentMethod }).subscribe(
        () => {
          resolve(false);
        },
        () => {
          reject();
        }
      );
    });
  }

  updateDetails (details): Promise<any> {
    return new Promise((resolve, reject) => {
      // return resolve();
      this._httpClient.put('/orders/' + this.order.id + '/items/details', { items: details }).subscribe(
        (response: any) => {
          this.onOrderDetailsUpdated.next(response.order as Order);
          resolve(false);
        },
        (error) => {
          console.log(error);
          reject();
        }
      );
    });
  }

  uploadGreenPass (image: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('image', image, image.name);

      this._httpClient.post('/orders/' + this.order.id + '/greenPass', formData).subscribe(
        (response: any) => {
          console.log(response);
          resolve(response);
        },
        () => {
          reject();
        }
      );
    });
  }

  savePaymentMethod (_id): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this._localStorage.getItem('token')) {
        return resolve(false);
      }

      const token = '?token=' + this._localStorage.getItem('token');

      this._httpClient.put('/users/self/paymentMethods/' + _id + token, { saved: true }).subscribe(
        (response: any) => {
          const index = this.paymentMethods.map((x) => x._id).indexOf(_id);
          this.paymentMethods[index] = response.paymentMethod;
          this.onPaymentMethodsChanged.next(this.paymentMethods);

          resolve(false);
        },
        () => {
          reject();
        }
      );
    });
  }

  completeOrder (promotionConsent, transactionId?, paymentDetails?, installments = 1): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put('/orders/' + this.order.id + '/complete', {
          promotionConsent,
          paymentDetails,
          transactionId,
          installments,
        })
        .subscribe(
          (response: any) => {
            this.order = new Order(response.order);
            this.onMaxInstallmentsChanged.next(undefined);
            this.getOrder();

            resolve(this.order);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateSplittedPaymentMethodCount (count): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put('/orders/' + this.order.id + '/splittedPaymentMethodsCount', {
          count,
        })
        .subscribe(
          () => {
            resolve(false);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addSplittedPaymentMethod (transactionId, paymentDetails, authNumber): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post('/orders/' + this.order.id + '/splittedPaymentMethods', {
          paymentDetails,
          transactionId,
          authNumber,
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            resolve(response.paymentSplitting);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  checkForCompletionErrors (details): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/orders/' + this.order.id + '/completionErrors', { items: details }).subscribe(
        (response: any) => {
          console.log(response);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  trackPurchaseEvent (): void {
    this._trackingService.triggerEvent('purchase', {
      currency: this.order.currency,
      transaction_id: this.order.id,
      value: this.order.total || this.order.subtotal,
      items: this.order.items
        .filter((x) => x.quantity)
        .map((x) => ({
          item: x.ticketType || x.product,
          quantity: x.quantity,
        })),
    });
  }

  updateLocalstorage (): void {
    if (this.order?.id) {
      if (!this.order.completed && !this.order.expired) {
        this._localStorage.setItem(
          'activeOrder',
          JSON.stringify({
            id: this.order.id,
            entity: this.order.venue?.id,
            ...(this.order?.ticketsSavedUntil ? { exp: new Date(this.order?.ticketsSavedUntil).getTime() } : {}),
          })
        );
      } else {
        this.removeFromLocalStorage();
      }
    }
  }

  removeFromLocalStorage (id = this.order.id): void {
    if (this._localStorage.getItem('activeOrder')) {
      if (JSON.parse(this._localStorage.getItem('activeOrder'))?.id === id) {
        this._localStorage.removeItem('activeOrder');
      }
    }
  }
}
