import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { LocalStorage } from '../../../../@fuse/services/storage/local-storage';
import { EnvService } from '../../../../env.service';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { Order } from '../../../main/orders/order/order.model';
import { Platform } from '../../models/platform.model';


@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: [ './stripe-payment.component.scss' ],
})
export class StripePaymentComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;

  currentLang;

  stripeScript;

  @Input() showChargeButton = true;
  @Input() showAmount = true;
  @Input() clientSecret;
  @Input() publicKey;
  @Input() paymentIntent;
  @Input() setupIntent;
  @Input() stripeAccount: string;

  @Input() charge: Observable<void>;
  @Input() order: Order;
  @Input() amount = 0;
  @Output() complete = new EventEmitter<any>();
  platform: Platform;

  private _unsubscribeAll = new Subject<void>();

  constructor (
    @Inject(DOCUMENT) private _document,
    private renderer2: Renderer2,
    private cd: ChangeDetectorRef,
    private _appService: AppService,
    private _appLocalizationService: AppLocalizationService,
    private _envService: EnvService,
    private _location: Location,
    private _localStorage: LocalStorage
  ) {}

  ngOnInit (): void {
    this._appLocalizationService.onCurrentLangChanged.subscribe((x) => (this.currentLang = x || 'en'));

    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((platform) => {
        this.platform = platform;
        this.initializeStripe();
      });

    if (this.charge) {
      this.charge.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        console.warn('On charge change');
        this.confirmStripePayment();
      });
    }
  }

  initiateCardElement (): void {
    // Giving a base style here, but most of the style is in scss file
    const cardStyle = {
      base: {
        'color': '#ffffff',
        'fontFamily':
          this.platform?.uiDefaultFonts?.map((x) => x.font)?.join(', ') ||
          'Rubik, "Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '14px',
        'iconColor': '#ffffff',
        '::placeholder': {
          color: '#A5A5A5',
          iconColor: '#A5A5A5',
        },
      },
      invalid: {
        color: '#F64D36',
        iconColor: '#F64D36',
      },
    };

    this.card = elements.create('payment', { style: cardStyle });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  initializeStripe (): void {
    const appearance = `{
      variables: {
        colorPrimary: '#22ECE9',
        colorDanger: '#F64D36',
        fontFamily: 'Outfit, Rubik, sans-serif',
      },
      theme: 'night',
      labels: 'floating',
      borderRadius: '8px',
      fontSize: '14px',
      rules: {
        '.Input': {
          transition: 'border 0.3s ease',
          colorDanger: '#F64D36',
          borderRadius: '8px',
          border: '1px solid transparent',
          backgroundColor: '#8D8D8D29',
          boxShadow: 'none',
        },
        '.Input--selected': {
          borderColor: '#22ECE9',
          boxShadow: 'none',
        },
        '.Input:focus': {
          borderColor: '#22ECE9',
          boxShadow: 'none',
        },
        '.Input--invalid': {
          boxShadow: 'none',
        },
        '.Label--resting': {
           fontSize: '14px',
        },
        '.Label--floating': {
           fontSize: '12px',
        },
        '.Error': {
          paddingLeft: '17px',
          paddingRight: '17px',
          fontSize: '10.5px'
        },
        '.Block': {
          borderRadius: '8px',
          border: '1px solid transparent',
          backgroundColor: '#8D8D8D29',
          boxShadow: 'none',
        },
        '.Tab': {
          borderRadius: '8px',
          border: '1px solid transparent',
          backgroundColor: '#8D8D8D29',
          boxShadow: 'none',
        },
        '.TabLabel': {
          fontWeight: '400',
        },
      }
    }`;

    this.stripeScript = this.renderer2.createElement('script');
    this.stripeScript.type = 'text/javascript';
    this.stripeScript.text = `
      stripe = Stripe('${this.publicKey}'${
        this.stripeAccount ? ',' + JSON.stringify({ stripeAccount: this.stripeAccount }) : ''
      });
      elements = stripe.elements({appearance: ${appearance}, clientSecret: '${this.clientSecret}'});`;
    this.stripeScript.id = 'stripe-script';
    this.renderer2.appendChild(this._document.body, this.stripeScript);
  }

  onChange ({ error }): void {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }

    this.cd.detectChanges();
  }

  async confirmStripePayment (): Promise<void> {
    try {
      let token = '';
      if (this._localStorage.getItem('x-access-token')) {
        token = '?atoken=' + this._localStorage.getItem('x-access-token');
      } else if (this._localStorage.getItem('gtoken')) {
        token = '?gtoken=' + this._localStorage.getItem('gtoken');
      }

      if (this.paymentIntent?.id) {
        const paymentIntent = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: `${
              this._envService?.settings?.base_url ||
              this.platform?.baseUrl ||
              environment?.baseUrl ||
              'https://chillz.com'
            }/${this.currentLang}/orders/${this.order.id}${token}`,
            // return_url: this._location.path()
          },
          // redirect: 'if_required'
        });

        console.log(paymentIntent);
        if (paymentIntent?.error) {
          this.onError(paymentIntent.error);
        } else if (paymentIntent?.status === 'succeeded') {
          this.onSuccess(paymentIntent.id);
        }
      } else if (this.setupIntent?.id) {
        const confirmation = await stripe.confirmSetup({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: `${
              this._envService?.settings?.base_url ||
              this.platform?.baseUrl ||
              environment?.baseUrl ||
              'https://chillz.com'
            }/${this.currentLang}/orders/${this.order.id}${token}`,
            // return_url: this._location.path()
          },
          redirect: 'if_required',
        });

        console.log(confirmation);
        if (confirmation?.error) {
          console.log(confirmation.error.message);
          this.onError(confirmation.error);
        } else if (confirmation?.setupIntent?.status === 'succeeded') {
          this.onSuccess(confirmation?.setupIntent.id);
        }
      }
    } catch (e) {
      this.onError(e);
    }
  }

  onSuccess (token): void {
    console.log(token);
    this.complete.emit({ success: token });
  }

  onError (error): void {
    if (error?.setup_intent?.status === 'succeeded' || error?.payment_intent?.status === 'succeeded') {
      this.onSuccess(error?.setup_intent.id);
    } else if (error.message) {
      this.cardError = error.message;
      this.complete.emit({ error: error.message });
    }
  }

  updatePayment (): void {
    this.destroyPayment();

    this.initializeStripe();
    this.initiateCardElement();
    this.confirmStripePayment().then();

    this.cd.detectChanges();
  }

  destroyPayment (): void {
    if (this.card) {
      // We remove event listener here to keep memory clean
      this.card?.removeEventListener('change', this.cardHandler);
      this.card?.destroy();
    }

    if (this.stripeScript) {
      this.renderer2.removeChild(this.renderer2.parentNode(this.stripeScript), this.stripeScript);
    }

    console.log('destroyed');
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.amount && !changes.amount.isFirstChange()) {
      console.warn('New Amount:', this.amount);
      this.updatePayment();
    }
  }

  ngAfterViewInit (): void {
    this.initiateCardElement();
  }

  ngOnDestroy (): void {
    this.destroyPayment();

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
