<div [ngClass]="type" class="verification-header w-100-p" *ngIf="email || phone">
  <div class="title mb-100">
    {{ (email ? 'SHARED_COMPONENTS.VERIFICATION.CHECK_YOUR_INBOX' : 'SHARED_COMPONENTS.VERIFICATION.CHECK_YOUR_PHONE') |
    translate }}
  </div>
  <div class="subtitle">
    {{ 'SHARED_COMPONENTS.VERIFICATION.CODE_SENT' | translate: { length: length, destination: email || phone } }}
  </div>
</div>

<code-input
  class="chillz-verification-code-container"
  [class.sending]="sending"
  [class.invalid]="error"
  [initialFocusField]="0"
  [isPrevFocusableAfterClearing]="true"
  [isCharsCode]="false"
  [codeLength]="length"
  [disabled]="isDisabled"
  (codeChanged)="onInputChange($event)"
></code-input>

<ng-container *ngIf="type === 'auth'">
  <ng-container *ngIf="error; else submitTemplate">
    <chillz-base-button
      [label]="{ en: 'SHARED_COMPONENTS.VERIFICATION.BACK' | translate }"
      [variant]="'secondary'"
      [size]="'large'"
      class="pop-animation action-button w-100-p"
      [disabled]="sending"
      (clicked)="onBack()"
    ></chillz-base-button>

    <div class="footer">
      <div class="block">
        <span>{{ error }}</span>
        <a class="form-link" (click)="onBack()">{{ 'SHARED_COMPONENTS.VERIFICATION.RESEND' | translate }}</a>
      </div>
    </div>
  </ng-container>

  <ng-template #submitTemplate>
    <chillz-base-button
      [label]="{ en: 'SHARED_COMPONENTS.VERIFICATION.SUBMIT' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      class="pop-animation action-button w-100-p"
      [disabled]="disableSubmit"
      [loading]="sending"
      (clicked)="onSubmit()"
    ></chillz-base-button>

    <div class="footer">
      <div class="block">
        <span>{{ 'SHARED_COMPONENTS.VERIFICATION.DIDNT_RECEIVED_A_CODE' | translate }}</span>
        <a class="form-link" (click)="onBack()">{{ 'SHARED_COMPONENTS.VERIFICATION.RESEND' | translate }}</a>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="type === 'settings'">
  <chillz-base-button
    [label]="{ en: 'SHARED_COMPONENTS.VERIFICATION.SUBMIT' | translate }"
    [variant]="'primary'"
    [size]="'large'"
    class="pop-animation action-button w-100-p"
    [disabled]="disableSubmit"
    [loading]="sending"
    (clicked)="onSubmit()"
  ></chillz-base-button>

  <div class="footer" *ngIf="displayErrors">
    <div class="block">
      <span>{{ error }}</span>
    </div>
  </div>
</ng-container>
