import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseShortcutsModule } from '../../../../@fuse/components';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { ChillzBaseButtonModule } from '../../../shared/components/chillz-base-button/chillz-base-button.module';
import { GSAPDirectivesModule } from '../../../shared/directives/gsap/gsap-directives.module';
import { ChillzSearchBarModule } from './search-bar/search-bar.module';
import { ToolbarComponent } from './toolbar.component';


@NgModule({
  declarations: [ ToolbarComponent ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,

    MatToolbarModule,

    ChillzSearchBarModule,

    FuseSharedModule,
    FuseShortcutsModule,
    MatRippleModule,
    TranslateModule,
    MatListModule,
    GSAPDirectivesModule,
    ChillzBaseButtonModule,
  ],
  exports: [ ToolbarComponent ],
})
export class ToolbarModule {}
