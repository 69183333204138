import { Direction } from '@angular/cdk/bidi';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { LocalStorage } from '../@fuse/services/storage/local-storage';
import { FuseTranslationLoaderService } from '../@fuse/services/translation-loader.service';
import { locale as english } from './main/i18n/en';
import { locale as hebrew } from './main/i18n/he';
import { Language } from './shared/models/language.model';


@Injectable({
  providedIn: 'root',
})
export class AppLocalizationService implements Resolve<any> {
  languages: Language[] = [
    new Language({
      id: 'en',
      title: 'English',
      flag: 'us',
    }),
    new Language({
      id: 'he',
      title: 'עברית',
      flag: 'il',
    }),
  ];

  LANGUAGES;

  routeParams = {};

  currentLang: string;
  onCurrentLangChanged: BehaviorSubject<string>;

  selectedLang: Language;

  textAttr: { direction: Direction; align: string };
  onTextAttrChanged: BehaviorSubject<{ direction: Direction; align: string }>;

  constructor (
    private _translateService: TranslateService,
    private _localStorage: LocalStorage,
    private _location: Location,
    private _router: Router,
    private _adapter: DateAdapter<any>,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.onTextAttrChanged = new BehaviorSubject(undefined);
    this.onCurrentLangChanged = new BehaviorSubject(_translateService.currentLang);

    // this.setLanguage(_translateService.currentLang);

    // this._translateService.onLangChange.pipe(skip(1))
    //   .subscribe(lang => {
    //   });

    // console.log('Current lang', _translateService.currentLang);
    // this._translateService.onLangChange.subscribe(lang => {
    //   console.log('onLangChange', lang);
    //   this.setLanguage(lang.lang);
    // });

    // this._router.events.pipe(filter((e) => e instanceof NavigationEnd))
    //   .subscribe(e => {
    //     console.log(this._router);
    //   });
  }

  resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    this.routeParams = route.params;

    this.setLanguage(this.routeParams['lang']);

    return new Promise((resolve, reject) => {
      Promise.all([]).then(() => {
        resolve(false);
      }, reject);
    });
  }

  setLanguages (languages: Language[], defaultLanguage: string): void {
    if (languages) {
      this.languages = languages;
    }

    this.LANGUAGES = this.languages.map((x) => x.id);

    this._translateService.addLangs(this.LANGUAGES);

    let lang = this.routeParams['lang'] || this._localStorage?.getItem('selected-lang');

    if (!lang || !this.LANGUAGES.includes(lang)) {
      lang = 'en';
      this._localStorage?.removeItem('selected-lang');
    }

    // Set the default language
    this._translateService.setDefaultLang(defaultLanguage || 'en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(english, hebrew);

    // Use a language
    // this._translateService.use(this.routeParams['lang']);

    this.setLanguage(this.routeParams['lang'] || this._translateService.currentLang);
  }

  /**
   * Set the language
   *
   */
  setLanguage (lang): void {
    // Use the selected language for translations
    this._translateService.use(lang);

    this.currentLang = lang;
    this.onCurrentLangChanged.next(lang);
    this.selectedLang = this.languages.find((x) => x.id === lang);

    if (this.selectedLang) {
      this.textAttr = { align: this.selectedLang.align, direction: this.selectedLang.direction };
    }

    if ([ 'he' ].includes(lang)) {
      this._adapter.setLocale('he-IL');
    } else {
      this._adapter.setLocale(lang);
    }

    this.onTextAttrChanged.next(this.textAttr);
  }
}
