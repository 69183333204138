<div class="order-preparation-container" [dir]="localizationService.textAttr?.direction">
  <div fadeInAnimation class="summary-wrapper">
    <div class="summary-container">
      <div class="summary-block">
        <div class="order-preparation-pricing-summary">
          <div class="order-subtotal">
            <chillz-skeleton-block
              *ngIf="state.loading || state.creating || !order || (startChangeTicketQuantity | async)"
              [height]="'18px'"
              class="order-price-loading"
            ></chillz-skeleton-block>
            <div *ngIf="order && order?.subtotal" class="order-subtotal-text">
              <p class="order-subtotal-after-discount B3-Medium m-0 black-50">
                {{ order?.subtotal | currency: order?.currency }}
              </p>
              <p *ngIf="order?.discount" class="order-subtotal-before-discount B6-Regular m-0 black-50">
                {{ order.subtotal + order?.discount | currency: order?.currency }}
              </p>
            </div>
          </div>
        </div>
        <chillz-base-button
          class="checkout-button"
          [variant]="'primary'"
          [class.disabled]="state.disabled"
          [size]="'large'"
          [fontSize]="'16px'"
          [padding]="'12px'"
          (clicked)="onGoToCheckout()"
          [label]="{ en: 'ORDER_PREPARATION.CONTINUE' | translate }"
        ></chillz-base-button>
      </div>
      <p
        *ngIf="(order?.total || order?.subtotal) && order?.total - order?.subtotal"
        class="subtotal-disclaimer B6-Regular black-50 m-0"
      >
        {{ 'ORDER_PREPARATION.SUBTOTAL_DISCLAIMER' | translate }}
      </p>
    </div>
    <div class="timer-block">
      <fuse-countdown
        *ngIf="order?.ticketsSavedUntil"
        [eventDate]="order?.seatsSavedUntil || order?.ticketsSavedUntil"
        [size]="'small'"
        (finished)="onCountdownFinished()"
        class="countdown"
        dir="ltr"
      ></fuse-countdown>
    </div>
  </div>
</div>
