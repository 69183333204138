import { AfterViewInit, Directive, ElementRef } from '@angular/core';


@Directive({
  selector: '[inputAutofocus]',
})
export class InputAutofocusDirective implements AfterViewInit {
  constructor (private host: ElementRef) {}

  ngAfterViewInit (): void {
    this.host.nativeElement.focus();
    this.host.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
