<div [formGroup]="formGroup" class="reset-password">
  <input *ngIf="username" hidden id="username" autocomplete="username" type="email" [value]="username" />

  <div class="passwords-form">
    <mat-form-field class="chillz-form-field" *ngIf="current">
      <mat-label>{{ 'SHARED_COMPONENTS.PASSWORD_CREATION.CONFIRM_CURRENT_PASSWORD' | translate }}</mat-label>
      <input matInput formControlName="currentPassword" type="password" autocomplete="current-password" />
      <mat-error *ngIf="formGroup.get('currentPassword').hasError('required')">
        {{ 'SHARED_COMPONENTS.PASSWORD_CREATION.YOUR_CURRENT_PASSWORD_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="chillz-form-field">
      <mat-label>{{ 'SHARED_COMPONENTS.PASSWORD_CREATION.NEW_PASSWORD' | translate }}</mat-label>
      <chillz-toggle-visibility #toggle matSuffix></chillz-toggle-visibility>
      <input
        #passwordWithValidation
        autocomplete="new-password"
        matInput
        [type]="toggle.type"
        [formControl]="passwordComponentWithValidation.passwordFormControl"
      />
      <mat-error *ngIf="formGroup?.get('password')?.hasError('required')">
        {{ 'SHARED_COMPONENTS.PASSWORD_CREATION.PASSWORD_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="password-strength pop-animation">
    <mat-password-strength
      class="chillz-password-strength"
      #passwordComponentWithValidation
      [enableDigitRule]="true"
      [enableLengthRule]="true"
      [enableSpecialCharRule]="false"
      [enableUpperCaseLetterRule]="true"
      [enableLowerCaseLetterRule]="true"
      [password]="passwordWithValidation.value"
    ></mat-password-strength>
    <!--Password's strength info-->
    <mat-password-strength-info
      class="chillz-password-strength-info"
      [matIconDone]="'check'"
      [matIconError]="null"
      [lowerCaseCriteriaMsg]="'SHARED_COMPONENTS.PASSWORD_CREATION.ONE_LOWERCASE_CHARACTER' | translate"
      [upperCaseCriteriaMsg]="'SHARED_COMPONENTS.PASSWORD_CREATION.ONE_UPPERCASE_CHARACTER' | translate"
      [minCharsCriteriaMsg]="'SHARED_COMPONENTS.PASSWORD_CREATION.MINIMUM_CHARACTERS' | translate"
      [digitsCriteriaMsg]="'SHARED_COMPONENTS.PASSWORD_CREATION.ONE_DIGIT' | translate"
      [passwordComponent]="passwordComponentWithValidation"
    ></mat-password-strength-info>
  </div>

  <mat-form-field class="mt-75 chillz-form-field" *ngIf="repeat">
    <mat-label>{{ 'SHARED_COMPONENTS.PASSWORD_CREATION.REPEAT_NEW_PASSWORD' | translate }}</mat-label>
    <chillz-toggle-visibility #repeatToggle matSuffix></chillz-toggle-visibility>
    <input autocomplete="new-password" matInput [type]="repeatToggle.type" formControlName="passwordConfirm" />
    <mat-error *ngIf="formGroup.get('passwordConfirm').hasError('required')">
      {{ 'SHARED_COMPONENTS.PASSWORD_CREATION.PASSWORD_CONFIRMATION_REQUIRED' | translate }}
    </mat-error>
    <mat-error
      *ngIf="
        !formGroup.get('passwordConfirm').hasError('required') &&
        formGroup.get('passwordConfirm').hasError('passwordsNotMatching')
      "
    >
      {{ 'SHARED_COMPONENTS.PASSWORD_CREATION.PASSWORD_MUST_MATCH' | translate }}
    </mat-error>
  </mat-form-field>
</div>
