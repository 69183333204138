import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputModule } from 'angular-code-input';

import { ChillzBaseButtonModule } from '../../shared/components/chillz-base-button/chillz-base-button.module';
import { ChillzButtonModule } from '../../shared/components/chillz-button/chillz-button.module';
import { ChillzDirectivesModule } from '../../shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../shared/directives/gsap/gsap-directives.module';
import { VerificationCodeComponent } from './verification-code/verification-code.component';


@NgModule({
  declarations: [ VerificationCodeComponent ],
  imports: [
    CommonModule,
    ChillzButtonModule,
    GSAPDirectivesModule,
    ChillzDirectivesModule,
    FlexModule,
    ChillzBaseButtonModule,
    TranslateModule,
    CodeInputModule,
  ],
  exports: [ VerificationCodeComponent ],
})
export class VerificationModule {}
