<form [formGroup]="socialMediaFormGroup">
  <mat-form-field class="chillz-form-field aligned-prefix">
    <mat-label>{{ 'PROFILE.INSTAGRAM' | translate }}</mat-label>
    <span matPrefix>@</span>
    <input matInput formControlName="instagram" />
    <mat-error *ngIf="socialMediaFormGroup.get('instagram').hasError('pattern')">
      {{ 'PROFILE.PATTERN_ERROR_STARTS_WITH_AT_SYMBOL' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="chillz-form-field aligned-prefix">
    <mat-label>{{ 'PROFILE.TIKTOK' | translate }}</mat-label>
    <span matPrefix>@</span>
    <input matInput formControlName="tiktok" />
    <mat-error *ngIf="socialMediaFormGroup.get('tiktok').hasError('pattern')">
      {{ 'PROFILE.PATTERN_ERROR_STARTS_WITH_AT_SYMBOL' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="chillz-form-field">
    <mat-label>{{ 'PROFILE.FACEBOOK' | translate }}</mat-label>
    <input matInput formControlName="facebook" />
    <mat-error *ngIf="socialMediaFormGroup.get('facebook').hasError('pattern')">
      {{ 'PROFILE.PATTERN_ERROR_FULL_URL' | translate }}
    </mat-error>
  </mat-form-field>

  <div class="actions">
    <chillz-base-button
      [label]="{ en: 'PROFILE.SAVE' | translate, he: 'PROFILE.SAVE' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      [disabled]="socialMediaFormGroup.invalid || socialMediaFormGroup.pristine"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-base-button>
  </div>
</form>
