import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ChillzCardModule } from '@chillz/ui-shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { FusePipesModule } from '../../../@fuse/pipes/pipes.module';
import { PasswordCreationModule } from '../../auth/password-creation/password-creation.module';
import { VerificationModule } from '../../auth/verification/verification.module';
import { ChillzBaseButtonModule } from '../../shared/components/chillz-base-button/chillz-base-button.module';
import { ChillzButtonModule } from '../../shared/components/chillz-button/chillz-button.module';
import { ChillzFormElementsModule } from '../../shared/components/chillz-form-elements/chillz-form-elements.module';
import { ChillzStepIndicatorModule } from '../../shared/components/chillz-step-indicator/chillz-step-indicator.module';
import { ChillzDirectivesModule } from '../../shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../shared/directives/gsap/gsap-directives.module';
import { ChillzPipesModule } from '../../shared/pipes/chillz-pipes.module';
import { ProfileComponent } from './profile.component';
import { ProfileEmailComponent } from './profile-email/profile-email.component';
import { ProfileGeneralDetailsComponent } from './profile-general-details/profile-general-details.component';
import { ProfileNameComponent } from './profile-name/profile-name.component';
import { ProfilePasswordComponent } from './profile-password/profile-password.component';
import { ProfilePhoneNumberComponent } from './profile-phone-number/profile-phone-number.component';
import { ProfileSocialMediaComponent } from './profile-social-media/profile-social-media.component';


@NgModule({
  declarations: [
    ProfileComponent,
    ProfileGeneralDetailsComponent,
    ProfilePhoneNumberComponent,
    ProfileEmailComponent,
    ProfileSocialMediaComponent,
    ProfilePasswordComponent,
    ProfileNameComponent,
  ],
  imports: [
    CommonModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    FormsModule,
    ChillzButtonModule,
    FlexLayoutModule,
    ChillzFormElementsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    VerificationModule,
    GSAPDirectivesModule,
    ChillzDirectivesModule,
    PasswordCreationModule,
    MatDialogModule,
    ChillzPipesModule,
    FusePipesModule,
    ChillzStepIndicatorModule,
    ChillzBaseButtonModule,
    ChillzCardModule,
    TranslateModule,
    MatIconModule,
    ChillzBaseButtonModule,
  ],
  exports: [
    ProfileComponent,
    ProfileGeneralDetailsComponent,
    ProfilePhoneNumberComponent,
    ProfileEmailComponent,
    ProfileSocialMediaComponent,
    ProfilePasswordComponent,
    ProfileNameComponent,
  ],
})
export class ProfileModule {}
