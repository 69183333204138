import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'chillz-toggle-visibility',
  templateUrl: './chillz-toggle-visibility.component.html',
  styleUrls: [ './chillz-toggle-visibility.component.scss' ],
})
export class ChillzToggleVisibilityComponent {
  @Output() typeChanged = new EventEmitter<'password' | 'text'>();

  public visible = false;
  public type = 'password';

  public onToggle (event: Event): void {
    event.stopPropagation();

    this.visible = !this.visible;

    this.visible ? this.typeChanged.emit('text') : this.typeChanged.emit('password');
    this.visible ? (this.type = 'text') : (this.type = 'password');
  }
}
