import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';

import { ChillzBaseButtonModule } from '../chillz-base-button/chillz-base-button.module';
import { TicketPortraitPosterFitComponent } from './ticket-portrait-poster-fit.component';


@NgModule({
  declarations: [ TicketPortraitPosterFitComponent ],
  imports: [ ChillzBaseButtonModule, QRCodeModule, CommonModule, TranslateModule ],
  exports: [ TicketPortraitPosterFitComponent ],
})
export class TicketPortraitPosterFitModule {}
