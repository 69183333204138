<div
  *ngIf="getButtonClasses()"
  [class]="getButtonClasses()"
  [class.disabled]="disabled"
  [class.loading]="loading"
  [class.transparency-disabled]="!transparency"
  [class.custom-padding]="padding"
  [class.custom-font]="fontSize"
  [class.enable-dynamic-color]="enableDynamicColor"
  [style.--color]="baseColor"
  [style.--font-color]="baseFontColor"
  [style.--icon-color]="iconColor ?? baseFontColor"
  [style.--custom-padding]="padding"
  [style.--custom-font-size]="fontSize"
  [routerLink]="link"
  [relativeTo]="relativeTo"
  [queryParams]="queryParams"
  (click)="!disabled ? clicked.emit($event) : undefined"
  class="chillz-base-button"
>
  <div class="background-layer" mat-ripple [matRippleAnimation]="{ enterDuration: 250, exitDuration: 250 }">
    <div class="inner-shadow-layer"></div>
    <div class="button-inside">
      <ng-container *ngIf="icon && iconPosition === 'before'">
        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      </ng-container>
      <p *ngIf="label" class="label">{{ label[localizationService.currentLang] || label['en'] }}</p>
      <ng-container *ngIf="icon && iconPosition === 'after'">
        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="border-gradient"></div>
</div>

<div
  *ngIf="getButtonClasses()"
  [class]="getButtonClasses()"
  [class.loading]="loading"
  [style.--color]="baseColor"
  [style.--font-color]="baseFontColor"
  [style.--icon-color]="iconColor ?? baseFontColor"
  class="base-button-loader-container"
>
  <div class="base-button-loader"></div>
</div>

<ng-template #iconTemplate>
  <mat-icon class="button-icon" *ngIf="isSvgIcon" [class]="iconClass" [svgIcon]="icon"></mat-icon>
  <mat-icon class="button-icon" *ngIf="!isSvgIcon" [class]="iconClass">{{ icon }}</mat-icon>
</ng-template>
