<form [formGroup]="nameFormGroup">
  <mat-form-field class="chillz-form-field">
    <mat-label>First Name</mat-label>
    <input matInput formControlName="firstName" />
  </mat-form-field>

  <mat-form-field class="chillz-form-field">
    <mat-label>Last Name</mat-label>
    <input matInput formControlName="lastName" />
  </mat-form-field>

  <div class="actions">
    <chillz-base-button
      [label]="{ en: 'PROFILE.SAVE' | translate, he: 'PROFILE.SAVE' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      [disabled]="nameFormGroup.invalid || nameFormGroup.pristine"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-base-button>
  </div>
</form>
