import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { Ticket } from '../../models/ticket.model';
import { TicketService } from '../ticket-view-dialog/ticket.service';


@Component({
  selector: 'app-ticket-portrait-poster-fit',
  templateUrl: './ticket-portrait-poster-fit.component.html',
  styleUrls: [ './ticket-portrait-poster-fit.component.scss' ],
})
export class TicketPortraitPosterFitComponent implements OnInit, OnDestroy {
  @Input() item: Ticket;
  @Input() dialogView: boolean;
  @Input() secondaryActions: boolean;

  qrcodeWidth: number;
  gettingApplePass: boolean;

  private readonly defaultQrCodeWidth: number;

  private _unsubscribeAll = new Subject<any>();

  constructor (
    public localizationService: AppLocalizationService,
    private _appService: AppService,
    private _ticketService: TicketService
  ) {
    this.defaultQrCodeWidth = 71;
  }

  ngOnInit (): void {
    this.setQrCodeWidth();
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  hasVenueValue (): boolean {
    return this.item.ticketType && !!this.item.ticketType.venue;
  }

  hasPicturesValue (): boolean {
    return (
      this.hasVenueValue() && this.item.ticketType.venue.pictures && this.item.ticketType.venue.pictures.length > 0
    );
  }

  hasFormattedStartedAtValue (): boolean {
    return (
      this.hasVenueValue() && !!this.item.ticketType.venue.startsAt && !!this.item.ticketType.venue.startsAt.formatted
    );
  }

  hasTitleEngValue (): boolean {
    return this.hasVenueValue() && !!this.item.ticketType.venue.titleEng;
  }

  hasLocationDetails (): boolean {
    return this.hasVenueValue() && !!this.item.ticketType.venue.locationDetails;
  }

  getApplePass (): void {
    this.gettingApplePass = true;
    this._ticketService
      .getTicketApplePass(this.item)
      .then((x) => console.log(x))
      .catch((err) => console.log(err))
      .finally(() => (this.gettingApplePass = false));
  }

  private setQrCodeWidth (): void {
    if (this.dialogView) {
      this.updateQrWidthOnResize();
    } else {
      this.qrcodeWidth = this.defaultQrCodeWidth;
    }
  }

  private updateQrWidthOnResize (): void {
    this.setMobileQrcodeWidth(this._appService.onScreenWidthChanged.getValue().width);

    this._appService.onScreenWidthChanged
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(300))
      .subscribe((width) => {
        this.setMobileQrcodeWidth(width.width);
      });
  }

  private setMobileQrcodeWidth (width: number): void {
    const middleScreenWidth = 957;
    const largeQrCodeWidth = 91;

    this.qrcodeWidth = width > middleScreenWidth ? largeQrCodeWidth : this.defaultQrCodeWidth;
  }
}
