import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChillzCardComponent } from './chillz-card.component';
import { ChillzCardContentComponent } from './chillz-card-content/chillz-card-content.component';
import { ChillzCardHeaderComponent } from './chillz-card-header/chillz-card-header.component';
import { ChillzCardSectionComponent } from './chillz-card-section/chillz-card-section.component';
import { ChillzCardSubheaderComponent } from './chillz-card-subheader/chillz-card-subheader.component';


@NgModule({
  declarations: [
    ChillzCardComponent,
    ChillzCardSectionComponent,
    ChillzCardHeaderComponent,
    ChillzCardSubheaderComponent,
    ChillzCardContentComponent,
  ],
  imports: [ CommonModule ],
  exports: [
    ChillzCardComponent,
    ChillzCardSectionComponent,
    ChillzCardHeaderComponent,
    ChillzCardSubheaderComponent,
    ChillzCardContentComponent,
  ],
})
export class ChillzCardModule {}
