import { Component, EventEmitter, Input, Output } from '@angular/core';

import { VerificationService } from '../verification.service';


@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: [ './verification-code.component.scss' ],
})
export class VerificationCodeComponent {
  @Input() token: string;
  @Input() length = 6;
  @Input() email: string;
  @Input() phone: string;
  @Input() type: 'auth' | 'settings' = 'auth';
  @Input() displayErrors = true;
  @Input() disableSubmit = true;

  @Output() tokenChange = new EventEmitter<string>();
  @Output() success = new EventEmitter<void>();
  @Output() fail = new EventEmitter<string>();
  @Output() back = new EventEmitter<void>();

  isDisabled: boolean;
  sending: boolean;
  error: string;
  inputValue: string;

  constructor (private _verificationService: VerificationService) {}

  onInputChange (value: string): void {
    if (value.length === this.length) {
      this.inputValue = value;
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }
  }

  onSubmit (): void {
    this.sending = true;
    this._verificationService
      .checkVerification(this.token, this.inputValue)
      .then(() => {
        this.token = undefined;
        this.tokenChange.emit(this.token);
        this.success.emit();
      })
      .catch((err) => {
        console.log(err);
        this.error = err?.error?.error;
        this.fail.emit(err?.error?.error);
      })
      .finally(() => (this.sending = false));
  }

  onBack (): void {
    this.back.emit();
  }
}
