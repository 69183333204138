export const locale = {
  lang: 'en',
  data: {
    PROFILE: {
      UPDATE_MY_PROFILE: 'Update My {{brandName}} Profile',
      NAME: 'Name',
      DETAILS: 'Details',
      PHONE_NUMBER: 'Phone Number',
      UPDATE_YOUR_EMAIL_ADDRESS: 'Update your Email Address',
      EMAIL: 'Email',
      SOCIAL_MEDIA: 'Social Media',
      PASSWORD: 'Password',
      SET_A_PASSWORD: 'Set a Password',
      SET_A: 'Set a',
      RESET: 'Reset',
      LAST_CHANGED: 'Last changed',
      PROFILE_SETTINGS: 'Profile Settings | {{brandName}} Admin Console',
      SAVE: 'Save',
      PATTERN_ERROR_STARTS_WITH_AT_SYMBOL:
        'Pattern is not valid. We only need a profile handle (starts with a "@"), not a full URL.',
      PATTERN_ERROR_FULL_URL: 'Pattern is not valid. We need a full URL.',
      INSTAGRAM: 'Instagram',
      TIKTOK: 'Tiktok',
      FACEBOOK: 'Facebook',
    },
  },
};
