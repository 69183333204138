import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ChillzAutocompleteComponent } from './chillz-autocomplete.component';


@NgModule({
  declarations: [ ChillzAutocompleteComponent ],
  imports: [ MatAutocompleteModule, MatFormFieldModule, ReactiveFormsModule, CommonModule, MatInputModule ],
  exports: [ ChillzAutocompleteComponent ],
})
export class ChillzAutocompleteModule {}
