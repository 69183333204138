import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { Platform } from '../../../shared/models/platform.model';


@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: [ './profile-password.component.scss', '../profile-form.scss' ],
})
export class ProfilePasswordComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;
  passwordError: string;
  passwordFormGroup: FormGroup;

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  private _unsubscribeAll = new Subject<void>();

  constructor (private _authService: ChillzAuthService, private _formBuilder: FormBuilder) {}

  ngOnInit (): void {
    this.passwordFormGroup = this._formBuilder.group({});

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
      });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(3000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');

    this._authService
      .updatePassword(
        this.passwordFormGroup.get('password').value,
        this._authService.getAccessToken(),
        this.passwordFormGroup.get('currentPassword')?.value
      )
      .then(() => {
        this.status$.emit('saved');
        this.passwordError = undefined;
        this.passwordFormGroup.reset({ emitEvent: false });
      })
      .catch((err) => {
        this.passwordError = err?.error?.error;
        this.status$.emit(null);
      })
      .finally(() => {
        this.passwordFormGroup.markAsPristine();
      });
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
