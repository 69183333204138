<form [formGroup]="passwordFormGroup">
  <app-password-creation
    [username]="profile?.email"
    [current]="profile.hasPassword"
    [repeat]="false"
    [formGroup]="passwordFormGroup"
  ></app-password-creation>

  <mat-error *ngIf="passwordError">{{ passwordError }}</mat-error>

  <div class="actions">
    <chillz-base-button
      [label]="{ en: 'PROFILE.SAVE' | translate, he: 'PROFILE.SAVE' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      [disabled]="passwordFormGroup.invalid"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-base-button>
  </div>
</form>
