import { Component, Input } from '@angular/core';

import { ChillzCardDirection, ChillzCardSize, ChillzCardVariant } from './chillz-card.model';


@Component({
  selector: 'chillz-card',
  templateUrl: './chillz-card.component.html',
  styleUrls: [ './chillz-card.component.scss' ],
})
export class ChillzCardComponent {
  @Input() variant: ChillzCardVariant = 'dark';
  @Input() direction: ChillzCardDirection = 'column';
  @Input() size: ChillzCardSize = 'large';
  @Input() background: string;

  @Input() padding: string;
}
