<form autocomplete="off">
  <chillz-phone-number-input
    [containerClass]="'chillz-phone-number-input'"
    [disabled]="!!phoneVerificationToken"
    [initialValue]="{ countryCode: profile.countryCode, phoneNumber: profile.phoneNumber }"
    (valueChanged)="onPhoneChanged($event)"
  ></chillz-phone-number-input>

  <app-verification-code
    [type]="'settings'"
    [displayErrors]="false"
    [disableSubmit]="disableSubmitCodeButton"
    [phone]="parsedPhoneNumber?.number?.national"
    *ngIfAnimated="phoneVerificationToken"
    (tokenChange)="!$event ? status$.next('saved') : null"
    [(token)]="phoneVerificationToken"
    (fail)="setPhoneError($event)"
  ></app-verification-code>

  <mat-error *ngIf="phoneError" fxFlex="1 1 auto">{{ phoneError }}</mat-error>

  <div class="actions">
    <chillz-base-button
      *ngIf="!phoneVerificationToken"
      [disabled]="!phoneNumberFormGroup?.valid || phoneNumberFormGroup.pristine || status === 'saving'"
      [label]="{ en: 'PROFILE.SAVE' | translate, he: 'PROFILE.SAVE' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-base-button>
  </div>
</form>
