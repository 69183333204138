import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { ChillzToggleVisibilityModule } from '@chillz/ui-shared/components';
import { TranslateModule } from '@ngx-translate/core';

import { ChillzDirectivesModule } from '../../shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../shared/directives/gsap/gsap-directives.module';
import { PasswordCreationComponent } from './password-creation.component';


@NgModule({
  declarations: [ PasswordCreationComponent ],
  imports: [
    CommonModule,
    MatPasswordStrengthModule,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexModule,
    ExtendedModule,
    ChillzToggleVisibilityModule,
    TranslateModule,
  ],
  exports: [ PasswordCreationComponent ],
})
export class PasswordCreationModule {}
