<form class="settings-section-body">
  <mat-form-field class="chillz-form-field">
    <mat-label>{{ 'PROFILE.EMAIL' | translate }}</mat-label>
    <input matInput [formControl]="emailFormControl" />
  </mat-form-field>

  <app-verification-code
    [type]="'settings'"
    *ngIfAnimated="emailVerificationToken"
    (tokenChange)="!$event ? status$.next('saved') : null"
    [(token)]="emailVerificationToken"
  ></app-verification-code>

  <mat-error *ngIf="emailError">{{ emailError }}</mat-error>

  <div class="actions">
    <chillz-base-button
      *ngIf="!emailVerificationToken"
      [label]="{ en: 'PROFILE.SAVE' | translate, he: 'PROFILE.SAVE' | translate }"
      [variant]="'primary'"
      [size]="'large'"
      [disabled]="emailFormControl.invalid || emailFormControl.pristine || profile.email === emailFormControl.value"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-base-button>
  </div>
</form>
