import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppLocalizationService } from '../../../app-localization.service';
import {
  ButtonActionVariant,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from './chillz-base-button.model';


@Component({
  selector: 'chillz-base-button',
  templateUrl: './chillz-base-button.component.html',
  styleUrls: [ './chillz-base-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChillzBaseButtonComponent {
  // HEX color seeds
  @Input() baseColor: string;
  @Input() baseFontColor: string;
  // label
  @Input() label: { [lang: string]: string };
  // main presets
  @Input() type: ButtonType = 'default';
  @Input() variant: ButtonVariant;
  @Input() size: ButtonSize = 'medium';
  @Input() actionVariant: ButtonActionVariant;
  // additional settings
  @Input() transparency = true;
  @Input() padding: string;
  @Input() fontSize: string;
  @Input() enableDynamicColor = true;
  // states
  @Input() disabled: boolean;
  @Input() loading: boolean;
  // icon
  @Input() isSvgIcon = true;
  @Input() icon: string;
  @Input() iconPosition: ButtonIconPosition = 'before';
  @Input() iconClass: string;
  @Input() iconColor: string;
  // router link
  @Input() link: string | string[];
  @Input() relativeTo: ActivatedRoute | null;
  @Input() queryParams: { [param: string]: string };

  @Output() clicked = new EventEmitter<Event>();

  constructor (public localizationService: AppLocalizationService, public elementRef: ElementRef) {}

  public getButtonClasses (): string {
    return [ this.actionVariant, `variant-${this.variant}`, this.size, this.type ].filter(Boolean).join(' ');
  }
}
