export const chillzIconsRegistry = [
  { name: 'chillz-alert-info', url: '/shared-assets/icons/chillz/alert-info.svg' },
  { name: 'chillz-arrow', url: '/shared-assets/icons/chillz/arrow.svg' },
  { name: 'chillz-arrow-back', url: '/shared-assets/icons/chillz/arrow-back.svg' },
  { name: 'chillz-cross', url: '/shared-assets/icons/chillz/cross.svg' },
  { name: 'chillz-arrow-short', url: '/shared-assets/icons/chillz/arrow-short.svg' },
  { name: 'chillz-copy', url: '/shared-assets/icons/chillz/copy.svg' },
  { name: 'chillz-export', url: '/shared-assets/icons/chillz/export.svg' },
  { name: 'chillz-password', url: '/shared-assets/icons/chillz/password.svg' },
  { name: 'chillz-promo-code-dollar', url: '/shared-assets/icons/chillz/promo-code-dollar.svg' },
  { name: 'chillz-promo-code-percent', url: '/shared-assets/icons/chillz/promo-code-percent.svg' },
  { name: 'chillz-team', url: '/shared-assets/icons/chillz/team.svg' },
  { name: 'chillz-back', url: '/shared-assets/icons/chillz/back.svg' },
  { name: 'chillz-checkmark', url: '/shared-assets/icons/chillz/checkmark.svg' },
  { name: 'chillz-edit', url: '/shared-assets/icons/chillz/edit.svg' },
  { name: 'chillz-guests', url: '/shared-assets/icons/chillz/guests.svg' },
  { name: 'chillz-home', url: '/shared-assets/icons/chillz/home.svg' },
  { name: 'chillz-instagram', url: '/shared-assets/icons/chillz/instagram.svg' },
  { name: 'chillz-settings', url: '/shared-assets/icons/chillz/settings.svg' },
  { name: 'chillz-summary', url: '/shared-assets/icons/chillz/summary.svg' },
  { name: 'chillz-ticket-reissue', url: '/shared-assets/icons/chillz/ticket-reissue.svg' },
  { name: 'chillz-ticket', url: '/shared-assets/icons/chillz/ticket.svg' },
  { name: 'chillz-unlock', url: '/shared-assets/icons/chillz/unlock.svg' },
  { name: 'chillz-user', url: '/shared-assets/icons/chillz/user.svg' },
  { name: 'chillz-calendar', url: '/shared-assets/icons/chillz/calendar.svg' },
  { name: 'chillz-finance', url: '/shared-assets/icons/chillz/finance.svg' },
  { name: 'chillz-rocket', url: '/shared-assets/icons/chillz/rocket.svg' },
  { name: 'chillz-send', url: '/shared-assets/icons/chillz/send.svg' },
  { name: 'chillz-user-profile', url: '/shared-assets/icons/chillz/user-profile.svg' },
  { name: 'chillz-facebook', url: '/shared-assets/icons/chillz/facebook.svg' },
  { name: 'chillz-phone', url: '/shared-assets/icons/chillz/phone.svg' },
  { name: 'chillz-message', url: '/shared-assets/icons/chillz/message.svg' },
  { name: 'chillz-more', url: '/shared-assets/icons/chillz/more.svg' },
  { name: 'chillz-time', url: '/shared-assets/icons/chillz/time.svg' },
  { name: 'chillz-not-show', url: '/shared-assets/icons/chillz/not-show.svg' },
  { name: 'chillz-lock', url: '/shared-assets/icons/chillz/lock.svg' },
  { name: 'chillz-approval', url: '/shared-assets/icons/chillz/approval.svg' },
  { name: 'chillz-align-center', url: '/shared-assets/icons/chillz/align-center.svg' },
  { name: 'chillz-align-top', url: '/shared-assets/icons/chillz/align-top.svg' },
  { name: 'chillz-align-bottom', url: '/shared-assets/icons/chillz/align-bottom.svg' },
  { name: 'chillz-color', url: '/shared-assets/icons/chillz/color.svg' },
  { name: 'chillz-photo', url: '/shared-assets/icons/chillz/photo.svg' },
  { name: 'chillz-image-upload', url: '/shared-assets/icons/chillz/image-upload.svg' },
  { name: 'chillz-dropper', url: '/shared-assets/icons/chillz/dropper.svg' },
  { name: 'chillz-video', url: '/shared-assets/icons/chillz/video.svg' },
  { name: 'chillz-key', url: '/shared-assets/icons/chillz/key.svg' },
  { name: 'chillz-question', url: '/shared-assets/icons/chillz/question.svg' },
  { name: 'chillz-warning', url: '/shared-assets/icons/chillz/warning.svg' },
  { name: 'chillz-arrow-left-up', url: '/shared-assets/icons/chillz/arrow-left-up.svg' },
  { name: 'chillz-filter', url: '/shared-assets/icons/chillz/filter.svg' },
  { name: 'chillz-exit', url: '/shared-assets/icons/chillz/exit.svg' },
  { name: 'chillz-event', url: '/shared-assets/icons/chillz/event.svg' },
  { name: 'chillz-minus', url: '/shared-assets/icons/chillz/minus.svg' },
  { name: 'chillz-search', url: '/shared-assets/icons/chillz/search.svg' },
  { name: 'chillz-no-data', url: '/shared-assets/icons/chillz/no-data.svg' },
  {
    name: 'chillz-distribute-vertical-spacing',
    url: '/shared-assets/icons/chillz/distribute-vertical-spacing.svg',
  },
  { name: 'chillz-show', url: '/shared-assets/icons/chillz/show.svg' },
  { name: 'chillz-mail', url: '/shared-assets/icons/chillz/mail.svg' },
  { name: 'chillz-menu', url: '/shared-assets/icons/chillz/menu.svg' },
  { name: 'chillz-burger', url: '/shared-assets/icons/chillz/burger.svg' },
  { name: 'chillz-organizations', url: '/shared-assets/icons/chillz/organizations.svg' },
  { name: 'chillz-past', url: '/shared-assets/icons/chillz/past.svg' },
  { name: 'chillz-external-link', url: '/shared-assets/icons/chillz/external-link.svg' },
  { name: 'chillz-inbox', url: '/shared-assets/icons/chillz/inbox.svg' },
  { name: 'chillz-success', url: '/shared-assets/icons/chillz/success.svg' },
  { name: 'chillz-notification', url: '/shared-assets/icons/chillz/notification.svg' },
  { name: 'chillz-chat', url: '/shared-assets/icons/chillz/chat.svg' },
  { name: 'chillz-arrow-top', url: '/shared-assets/icons/chillz/arrow-top.svg' },
  { name: 'chillz-location', url: '/shared-assets/icons/chillz/location.svg' },
  { name: 'chillz-network', url: '/shared-assets/icons/chillz/network.svg' },
  { name: 'chillz-dispute', url: '/shared-assets/icons/chillz/dispute.svg' },

  // 20px
  { name: 'chillz-plus', url: '/shared-assets/icons/chillz/plus.svg' },
  { name: 'chillz-link', url: '/shared-assets/icons/chillz/link.svg' },
  { name: 'chillz-arrow-transition', url: '/shared-assets/icons/chillz/arrow-transition.svg' },
  { name: 'chillz-list', url: '/shared-assets/icons/chillz/list.svg' },
  { name: 'chillz-grid', url: '/shared-assets/icons/chillz/grid.svg' },
  { name: 'chillz-twitter', url: '/shared-assets/icons/chillz/twitter.svg' },
  { name: 'chillz-money', url: '/shared-assets/icons/chillz/money.svg' },
  { name: 'chillz-money-late', url: '/shared-assets/icons/chillz/money-late.svg' },
  { name: 'chillz-checked-in', url: '/shared-assets/icons/chillz/checked-in.svg' },
  { name: 'chillz-canceled-event', url: '/shared-assets/icons/chillz/canceled-event.svg' },
  { name: 'chillz-issued-manually', url: '/shared-assets/icons/chillz/issued-manually.svg' },
  { name: 'chillz-google', url: '/shared-assets/icons/chillz/google.svg' },
  { name: 'chillz-apple', url: '/shared-assets/icons/chillz/apple.svg' },
  { name: 'chillz-dropdown-toggle', url: '/shared-assets/icons/chillz/dropdown-toggle.svg' },
  { name: 'chillz-apple-wallet', url: '/shared-assets/icons/chillz/apple-wallet.svg' },

  // 16px
  { name: 'chillz-calendar-linear', url: '/shared-assets/icons/chillz/calendar-linear.svg' },
  { name: 'chillz-delete', url: '/shared-assets/icons/chillz/delete.svg' },
  { name: 'chillz-view', url: '/shared-assets/icons/chillz/view.svg' },
  { name: 'chillz-refresh', url: '/shared-assets/icons/chillz/refresh.svg' },
  { name: 'chillz-expend', url: '/shared-assets/icons/chillz/expend.svg' },
  { name: 'chillz-thumb-up', url: '/shared-assets/icons/chillz/thumb-up.svg' },
  { name: 'chillz-play', url: '/shared-assets/icons/chillz/play.svg' },
  { name: 'chillz-pause', url: '/shared-assets/icons/chillz/pause.svg' },

  // 12px
  { name: 'chillz-star', url: '/shared-assets/icons/chillz/star.svg' },
  { name: 'chillz-info', url: '/shared-assets/icons/chillz/info.svg' },
  { name: 'chillz-info-transparent', url: '/shared-assets/icons/chillz/info-transparent.svg' },
];
