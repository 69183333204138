<div class="ticket-container" [class.large]="dialogView">
  <div [class.ticket-cancelled]="item.cancelled" class="ticket-item">
    <div class="ticket-section ticket-main-info">
      <div class="ticket-header">
        <img
          *ngIf="hasPicturesValue() && item.ticketType.venue.pictures[0].url"
          [src]="item.ticketType.venue.pictures[0].url"
          class="ticket-avatar"
          alt="{{ hasTitleEngValue() ? item.ticketType.venue.titleEng : 'ticket-avatar' }}"
        />
        <p class="H4-Regular black-50 break-word ticket-name">{{ item.fullName }}</p>
      </div>
      <div class="details">
        <div *ngIf="hasTitleEngValue()" class="detail-event">
          <p class="item-title">{{ 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.EVENT' | translate }}</p>
          <p class="item-subtitle break-word">{{ item.ticketType.venue.titleEng }}</p>
        </div>
        <div *ngIf="item.ticketType" class="detail-type">
          <p class="item-title">{{ 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.TICKET_TYPE' | translate }}</p>
          <p class="item-subtitle break-word">{{ item.ticketType.title | titlecase }}</p>
        </div>
        <div *ngIf="hasFormattedStartedAtValue()" class="detail-date">
          <p class="item-title">{{ 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.DATE_AND_TIME' | translate }}</p>
          <p class="item-subtitle">
            {{ item.ticketType.venue.startsAt.formatted[localizationService.currentLang] ??
            item.ticketType.venue.startsAt.formatted['en'] }}
          </p>
        </div>
        <div class="detail-location">
          <p class="item-title">{{ 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.WHERE' | translate }}</p>
          <p class="item-subtitle break-word" *ngIf="hasLocationDetails()">
            <ng-container *ngIf="item.ticketType.venue.locationDetails.formatted; else displayCityTitle">
              {{ item.ticketType.venue.locationDetails.formatted }}
            </ng-container>
            <ng-template #displayCityTitle>
              <ng-container
                *ngIf="
                  item.ticketType.venue.locationDetails.cityObj && item.ticketType.venue.locationDetails.cityObj.titles
                "
              >
                {{ item.ticketType.venue.locationDetails.cityObj.titles[localizationService.currentLang] ??
                item.ticketType.venue.locationDetails.cityObj.titles['en'] }}
              </ng-container>
            </ng-template>
          </p>
        </div>

        <div class="detail-price" *ngIf="item.payment && item.payment.sum !== undefined">
          <p class="item-title">{{ 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.PRICE' | translate }}</p>
          <p class="item-subtitle">{{ item.payment.sum | currency: item.payment.currency }}</p>
        </div>
      </div>
    </div>
    <div class="ticket-section">
      <div class="half-circle"></div>
      <div class="dotted-line"></div>
      <div class="half-circle"></div>
    </div>
    <div class="ticket-section ticket-footer">
      <div class="qr-code">
        <qrcode
          [qrdata]="item.id"
          [allowEmptyString]="true"
          [colorDark]="item.ticketType?.venue?.accentColor"
          [colorLight]="'#ffffffff'"
          [margin]="0"
          [elementType]="'img'"
          [errorCorrectionLevel]="'M'"
          [scale]="32"
          [width]="qrcodeWidth"
        ></qrcode>
      </div>
      <p class="qr-code-id B5-Regular black-300" *ngIf="dialogView">{{ item.id }}</p>
    </div>
  </div>
  <div *ngIf="item.cancelled" class="cancelled">
    {{ 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.CANCELED' | translate }}
  </div>

  <div class="mt-16">
    <chillz-base-button
      *ngIf="!dialogView && !item.cancelled"
      [icon]="'chillz-apple-wallet'"
      [variant]="secondaryActions ? 'secondary' : undefined"
      [iconClass]="'fill-default'"
      [enableDynamicColor]="false"
      (clicked)="$event.stopPropagation(); getApplePass()"
      [label]="{ en: 'SHARED_COMPONENTS.TICKET_PORTRAIT_POSTER_FIT.ADD_TO_WALLET' | translate }"
      [baseColor]="secondaryActions ? undefined : '#141414'"
      [size]="'large'"
      [loading]="gettingApplePass"
    ></chillz-base-button>
  </div>
</div>
