import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { ChillzToggleVisibilityComponent } from './chillz-toggle-visibility.component';


@NgModule({
  declarations: [ ChillzToggleVisibilityComponent ],
  imports: [ CommonModule, MatIconModule, MatRippleModule ],
  exports: [ ChillzToggleVisibilityComponent ],
})
export class ChillzToggleVisibilityModule {}
