import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { chillzIconsRegistry } from '@chillz/ui-shared/assets';

import { ServerService } from '../../server.service';


@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor (
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _platform: Platform,
    private _server: ServerService
  ) {}

  public registerIcons (): void {
    this.registerChillzIcons();
  }

  add (icons: { name: string; url: string }[]): void {
    for (const icon of icons) {
      let url = icon.url;
      if (!this._platform.isBrowser) {
        // proper SSR url
        url = this._server.url + url;
      }

      this._matIconRegistry.addSvgIcon(icon.name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
    }
  }

  private registerChillzIcons (): void {
    this.add(chillzIconsRegistry);
  }
}
