import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SeoService } from '../../../@fuse/services/seo.service';
import { FuseTranslationLoaderService } from '../../../@fuse/services/translation-loader.service';
import { AppService } from '../../app.service';
import { ChillzAuthService } from '../../auth/chillz-auth.service';
import { User } from '../../auth/user.model';
import { SettingsDialogNewComponent } from '../../shared/components/dialogs/settings-dialog-new/settings-dialog-new.component';
import { Platform } from '../../shared/models/platform.model';
import { locale as english } from './i18n/en';
import { locale as hebrew } from './i18n/he';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [ './profile.component.scss' ],
})
export class ProfileComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  private _unsubscribeAll = new Subject<void>();

  constructor (
    private _authService: ChillzAuthService,
    private _appService: AppService,
    public _matDialog: MatDialog,
    public translateService: TranslateService,
    private _seo: SeoService,
    private translationLoader: FuseTranslationLoaderService
  ) {
    this.translationLoader.loadTranslations(english, hebrew);
  }

  ngOnInit (): void {
    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        console.log(this.profile);
      });

    this._appService.onPlatformChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((platform) => {
        this.platform = platform;
        this._seo.title = `Profile Settings | ${this._appService?.platform?.brandName?.en || ''}`;
      });
  }

  onOpenDialog (subType: string, title: { [lang: string]: string }, fullScreen = false): void {
    this._matDialog.open(SettingsDialogNewComponent, {
      panelClass: [ 'chillz-dialog', ...(fullScreen ? [ 'full-screen' ] : []) ],
      data: {
        steps: [
          {
            type: 'profile',
            subType,
            title,
            skipAllowed: false,
            closeAllowed: true,
          },
        ],
        hideActions: true,
      },
    });
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
