<mat-form-field [class]="fieldClass" class="w-100-p chillz-form-field">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [required]="required"
    [formControl]="control"
    [errorStateMatcher]="matcher"
    (input)="valueChange()"
    [matAutocomplete]="auto"
  />

  <mat-autocomplete
    class="chillz-autocomplete-panel"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event.option.value)"
  >
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <ng-container
        *ngIf="optionTemplate; else defaultOption"
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-container>
      <ng-template #defaultOption>
        {{ item['name'] ? item['name'][currentLanguage] ?? item['name']['en'] : '' }}
      </ng-template>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
