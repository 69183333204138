import { AbstractControl, FormArray, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMAIL_REG_EXP, FACEBOOK_REG_EXP, INSTAGRAM_REG_EXP, TIKTOK_REG_EXP } from '@chillz/constants';


export const emailValidator = Validators.pattern(EMAIL_REG_EXP);

export const instagramValidator = Validators.pattern(INSTAGRAM_REG_EXP);

export const facebookValidator = Validators.pattern(FACEBOOK_REG_EXP);

export const tiktokValidator = Validators.pattern(TIKTOK_REG_EXP);

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

export const uniqueArrayValidator: (fieldKey: string, language?: string) => ValidatorFn = (
  fieldKey: string,
  language?: string
) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormArray)) {
      return null;
    }

    const encounteredValues = new Set<string>();
    const duplicates: { title: string; index: number }[] = [];

    control.controls.forEach((control, index) => {
      const titleControl = language ? control.get(fieldKey)?.get(language) : control.get(fieldKey);

      if (titleControl) {
        const errors = { ...titleControl.errors };
        if (errors?.['notUnique']) {
          delete errors['notUnique'];
        }

        titleControl.setErrors(Object.keys(errors || {}).length ? errors : null);

        const title = titleControl.value;
        if (title && encounteredValues.has(title)) {
          duplicates.push({ title, index });
        } else {
          encounteredValues.add(title);
        }
      }
    });

    duplicates.forEach(({ index }) => {
      const controlItem = language
        ? control.controls[index].get(fieldKey)?.get(language)
        : control.controls[index].get(fieldKey);

      if (controlItem) {
        controlItem.setErrors({ notUnique: true });
      }
    });

    return duplicates.length > 0 ? { notUniqueArray: true } : null;
  };
};

export const stringValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return typeof control.value === 'string' ? { invalidType: true } : null;
};
