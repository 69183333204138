import { animate, state, style, transition, trigger } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, throttleTime } from 'rxjs/operators';

import { fuseAnimations } from '../@fuse/animations';
import { FuseNavigationService } from '../@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '../@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '../@fuse/services/config.service';
import { IconsService } from '../@fuse/services/icons.service';
import { SeoService } from '../@fuse/services/seo.service';
import { LocalStorage } from '../@fuse/services/storage/local-storage';
import { SessionStorage } from '../@fuse/services/storage/session-storage';
import { FuseTranslationLoaderService } from '../@fuse/services/translation-loader.service';
import { AppService } from './app.service';
import { ChillzAuthService } from './auth/chillz-auth.service';
import { LoadingService } from './loading.service';
import { OrdersService } from './main/orders/orders.service';
import { ProfileService } from './main/profile-settings/profile.service';
import { locale as navigationEnglish } from './navigation/i18n/en';
import { navigation } from './navigation/navigation';
import { OrderPreparationService } from './shared/components/order-preparation/order-preparation.service';
import { ExploreItem } from './shared/models/explore-item.model';
import { SessionInfoService } from './shared/session-info.service';
import { SplashService } from './splash.service';


@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  animations: [
    ...fuseAnimations,
    trigger('slideInBottom', [
      state(
        'void',
        style({
          transform: 'translateY(50%)',
          opacity: 0,
        })
      ),
      state(
        '*',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('void => *', animate('300ms ease-out')),
      transition('* => void', animate('300ms ease-out')),
    ]),
    trigger('minimize', [
      state('collapsed', style({ transform: 'translateY(70%)' })),
      state('expanded', style({ y: '*' })),
      transition('expanded <=> collapsed', animate('500ms ease-in-out')),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  fuseConfig: any;

  navigation: any;
  embedded;

  splashItem: ExploreItem;

  showCookiesConsent = false;
  showOrderPreparation = false;
  orderPreparationMinimized = false;
  isBrowser: boolean;
  isPreview: boolean;

  loading: boolean;

  private _unsubscribeAll: Subject<any>;
  private _loaderModalName = 'loader';

  constructor (
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) platformId,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private ngZone: NgZone,
    private _sessionInfoService: SessionInfoService,
    private _location: Location,
    private _router: Router,
    private _splashService: SplashService,
    private _appService: AppService,
    private _route: ActivatedRoute,
    private _seo: SeoService,
    private _localStorage: LocalStorage,
    private _sessionStorage: SessionStorage,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _changeDetectorRef: ChangeDetectorRef,
    private _orderPreparationService: OrderPreparationService,
    private _ordersService: OrdersService,
    private _renderer2: Renderer2,
    private _authService: ChillzAuthService,
    private _profileService: ProfileService,
    private _icons: IconsService,
    private _loadingService: LoadingService
  ) {
    this._icons.registerIcons();
    // Get default navigation
    this.navigation = navigation;

    this.isBrowser = isPlatformBrowser(platformId);

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages

    // Set the default language
    // this._translateService.setDefaultLang('he');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(navigationEnglish);

    this._sessionInfoService.getSessionInfo();

    if (this.isBrowser) {
      this._route.queryParams.subscribe((params) => {
        if (params['embed'] === '1') {
          this.embedded = true;
        }

        if (params['previewToken']) {
          this._localStorage.setItem('previewToken', params['previewToken']);
        }
      });

      this._appService.isPreview$.subscribe((isPreview) => {
        this.isPreview = isPreview;
      });
    }

    // this.ngZone.(() => {
    //   setTimeout(() => {

    //   }, 20000);
    // });

    // this._seo.reset();

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/facebook.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/search.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'order-pending',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/order-pending.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'coinz',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/coinz.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'tooltip',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/tooltip.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'image-edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/image-edit.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/settings.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'coinz-purple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/coinz-purple.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'glass-purple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/glass-martini-purple.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'trash-grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/trash-grey.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'shopping-bag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/shopping-bag.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'price-tag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/price-tag.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'location-marker',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/location-marker.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'credit-card',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/credit-card.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'apple-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/apple-logo.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/wallet.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'lock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/lock.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'location-marker-purple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/location-marker-purple.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/calendar.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/instagram.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'time',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/time.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'contact-phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/contact-phone.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'contact-email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/contact-email.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'tiktok',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/tiktok.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'spotify',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/spotify.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'apple-music',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/apple-music.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/youtube.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'checkmark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/checkmark.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'soundcloud',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/customized/soundcloud.svg')
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit (): void {
    this.handleResizeChange();
    this.handleScrollChange();
    this.handleLoadingContent();
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;

      // Boxed
      if (this.fuseConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (const className of this.document.body.classList) {
        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });

    this._splashService.onSplashItemChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((splashItem) => {
      this.splashItem = splashItem;
    });
  }

  private handleLoadingContent (): void {
    this._loadingService.loading.pipe(takeUntil(this._unsubscribeAll)).subscribe((loading) => {
      this.loading = loading;
      if (this.loading) {
        this._appService.openModal(this._loaderModalName);
      } else {
        this._appService.closeModal(this._loaderModalName);
      }
    });
  }

  handleResizeChange (): void {
    fromEvent(window, 'resize')
      .pipe(throttleTime(300), takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._appService.getScreenSize();
      });
  }

  handleScrollChange (): void {
    fromEvent(document, 'scroll')
      .pipe(throttleTime(300), takeUntil(this._unsubscribeAll))
      .subscribe((event) => {
        this._appService.scroll$.next(event);
      });
  }

  dismissCookiesConsent (): void {
    this._localStorage.setItem('cookiesConsentDismissed', 'true');
    this.showCookiesConsent = false;
  }

  ngAfterViewInit (): void {
    if (this.isBrowser) {
      setTimeout(() => {
        if (this._appService.platform?.cookiesPopup) {
          this.showCookiesConsent = this._localStorage.getItem('cookiesConsentDismissed') !== 'true';
          this._changeDetectorRef.detectChanges();
        }
      }, 10000);

      this._appService.hubspotPageView$
        .pipe(distinctUntilChanged(), debounceTime(5000), takeUntil(this._unsubscribeAll))
        .subscribe((url) => {
          const hubspot = this._renderer2.createElement('script');
          hubspot.text = `
                var _hsq = window._hsq = window._hsq || [];
                  _hsq.push(['setPath', '${url}']);
                  _hsq.push(['trackPageView']);
        `;
          this._renderer2.appendChild(this.document.head, hubspot);
        });

      this._authService.onUserChanged
        .pipe(
          takeUntil(this._unsubscribeAll),
          filter((x) => !!x)
        )
        .subscribe(() => {
          this._profileService.missingProfileDetails();
        });
      combineLatest(
        this._authService.onUserChanged.pipe(startWith(null)),
        this._ordersService.onOrderDetailsUpdated.pipe(startWith(null))
      )
        .pipe(
          debounceTime(5000),
          map(([ user, order ]) =>
            user?.email ||
              (order?.items?.[0]?.ticketDetails instanceof FormArray
                ? order?.items?.[0]?.ticketDetails?.at(0)?.get('email')?.value
                : order?.items?.[0]?.ticketDetails?.[0]?.email)),
          filter((email) => !!email),
          distinctUntilChanged(),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe((email) => {
          const hubspot = this._renderer2.createElement('script');
          hubspot.text = `
                var _hsq = window._hsq = window._hsq || [];
                  _hsq.push(['identify', {email: '${email}'}]);
        `;
          this._renderer2.appendChild(this.document.head, hubspot);
        });

      this._router.events
        .pipe(
          filter((x) => x instanceof NavigationEnd),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe((event: NavigationEnd) => {
          this._appService.hubspotPageView$.next(event.url);
        });
    }

    this._orderPreparationService.onStateChanged.subscribe((s) => {
      this.showOrderPreparation = !s.hidden;
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   */
  toggleSidebarOpen (key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
