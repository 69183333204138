import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppLocalizationService } from '../../../../app-localization.service';
import { Ticket } from '../../../models/ticket.model';


@Component({
  selector: 'app-ticket-portrait-poster-fit-dialog',
  templateUrl: './ticket-portrait-poster-fit-dialog.component.html',
  styleUrls: [ './ticket-portrait-poster-fit-dialog.component.scss' ],
})
export class TicketPortraitPosterFitDialogComponent implements OnInit {
  item: Ticket;

  constructor (
    public localizationService: AppLocalizationService,
    public matDialogRef: MatDialogRef<TicketPortraitPosterFitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: { item: Ticket }
  ) {
    this.item = _data.item;
  }

  ngOnInit (): void {}
}
