import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher, MatOptionSelectionChange } from '@angular/material/core';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { BaseAutocompleteOption, FormFieldAppearanceClass } from './chillz-autocomplete.model';


export class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor (private customControl: FormControl, private errors: ValidationErrors) {}

  isErrorState (): boolean {
    return this.customControl && this.customControl.touched && (this.customControl.invalid || !!this.errors);
  }
}

@Component({
  selector: 'chillz-autocomplete',
  templateUrl: './chillz-autocomplete.component.html',
  styleUrls: [ './chillz-autocomplete.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChillzAutocompleteComponent),
      multi: true,
    },
  ],
})
export class ChillzAutocompleteComponent<T> implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() options: T[] = [];
  @Input() label: string;
  @Input() required: boolean;
  @Input() currentLanguage = 'en';
  @Input() fieldClass: FormFieldAppearanceClass = 'appearance-gradient-gray';
  @Input() display: (item?: T | BaseAutocompleteOption) => string;
  @Input() allowCustomValue: boolean;
  @Input()
  get errors (): ValidationErrors {
    return this._errors;
  }

  set errors (value: ValidationErrors) {
    if (this._errors !== value) {
      this._errors = value;
      this.matcher = new CustomFieldErrorMatcher(this.control, this._errors);
    }
  }

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;

  public control = new FormControl(undefined);
  public filteredItems: Observable<T[] | BaseAutocompleteOption[]>;
  public matcher = null;

  private _errors: ValidationErrors;
  private onChangeCallback: (_: T | BaseAutocompleteOption | null) => void = () => {};
  private onTouchedCallback: () => void = () => {};
  private _unsubscribeAll = new Subject<void>();

  constructor () {}

  public ngOnInit (): void {
    this.applyFilteredItems();
  }

  public ngAfterViewInit (): void {
    this.trigger.panelClosingActions.pipe(takeUntil(this._unsubscribeAll)).subscribe((e) => this.handlePanelClosing(e));
  }

  public ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public onOptionSelected (option: T | BaseAutocompleteOption): void {
    this.onChangeCallback(option);
  }

  public writeValue (item: T | BaseAutocompleteOption | null): void {
    this.control.setValue(item);
  }

  public registerOnChange (fn: (value: T | BaseAutocompleteOption | null) => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched (fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  public displayFn = (item: T | BaseAutocompleteOption): string => {
    if (typeof this.display === 'function') {
      return this.display(item);
    }

    if (typeof item === 'object' && item !== null && 'name' in item) {
      return item.name[this.currentLanguage];
    }

    return '';
  };

  public valueChange (): void {
    this.handleFilledOption();
    if (this.filledOptionIndex() === -1) {
      this.onChangeCallback(this.control.value);
    }
  }

  private handleFilledOption (): void {
    const filledOptionIndex = this.filledOptionIndex();
    if (filledOptionIndex > -1) {
      this.applyInputValue(filledOptionIndex);
    }
  }

  private handlePanelClosing (event: MatOptionSelectionChange): void {
    if (!(event && event.source) && typeof this.control.value === 'string') {
      this.handleFilledOption();
      if (this.filledOptionIndex() === -1 && !this.allowCustomValue) {
        this.control.setValue(null);
        this.onOptionSelected(null);
        this.trigger.closePanel();
      }
    }
  }

  private applyInputValue (filledOptionIndex: number): void {
    this.onOptionSelected(this.options[filledOptionIndex]);
  }

  private applyFilteredItems (): void {
    this.filteredItems = this.control.valueChanges.pipe(
      startWith(''),
      map((item) => (item ? this.filter(item) : this.options.slice()))
    );
  }

  private filter (term: T | string): T[] {
    if (typeof term === 'string') {
      const lowerCaseTerm = term.toLowerCase();
      return this.options.filter((item) => this.displayFn(item).toLowerCase().includes(lowerCaseTerm));
    }

    return this.options;
  }

  private filledOptionIndex (): number {
    return this.options.findIndex((item: T) => this.displayFn(item) === this.control.value);
  }
}
