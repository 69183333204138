export const INVALID_EMAIL_SYMBOLS_REG_EXP = new RegExp(/[^\w-\\+\\.@]/, 'g');

export const EMAIL_REG_EXP = new RegExp('^[\\w-\\.\\+]+@([\\w-]+\\.)+[\\w-]{2,}$');

export const INVALID_FACEBOOK_URL_SYMBOLS_REG_EXP = new RegExp(/((\?|&)+(?!id)[\d\w_\-.]*=?[^?&]*|[\s]*)/, 'g');

export const FACEBOOK_REG_EXP = new RegExp('^(?:(?:http|https):\\/\\/)?(?:www.)?(?:m.)?facebook.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[?\\w\\-]*\\/)?(?:profile.php\\/?\\?id=(?=\\d.*))?([^\\s\\/\\\\&?]*)?$');

export const INVALID_SOCIAL_MEDIA_USERNAME_SYMBOLS_REG_EXP = new RegExp(/[\s@]*/, 'g');

export const TIKTOK_REG_EXP = new RegExp('^(?!.*\\.\\.)(?!.*\\.$)[a-z1-9-_\\.]{0,29}$');

export const INSTAGRAM_REG_EXP = new RegExp('^(?!.*\\.\\.)(?!.*\\.$)[^\\W][\\w.]{0,29}$');
