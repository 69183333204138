import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppLocalizationService } from '../../../../app-localization.service';


export interface IChillzDialogStep {
  type: 'profile';
  subType: string;
  stepIndicatorLabel?: { [lang: string]: string };
  title?: { [lang: string]: string };
  subtitle?: { [lang: string]: string };
  description?: { [lang: string]: string };
  lottie?: {
    src: string;
    width?: string;
    height?: string;
  };
  skipAllowed?: boolean;
  closeAllowed?: boolean;
}

@Component({
  selector: 'app-settings-dialog-new',
  templateUrl: './settings-dialog-new.component.html',
  styleUrls: [ './settings-dialog-new.component.scss' ],
})
export class SettingsDialogNewComponent implements OnInit {
  steps: IChillzDialogStep[] = [];

  currentStep: number;
  hideActions: boolean;

  title: { [lang: string]: string };
  subtitle: { [lang: string]: string };
  description: { [lang: string]: string };

  confirm: { [lang: string]: string };
  cancel: { [lang: string]: string };
  skip: { [lang: string]: string };

  stepIndicatorSteps;

  constructor (
    public dialogRef: MatDialogRef<SettingsDialogNewComponent>,
    public localizationService: AppLocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    this.title = data.title;
    this.subtitle = data.subtitle;
    this.description = data.description;

    this.confirm = data.confirm;
    this.cancel = data.cancel || { en: 'Cancel' };
    this.skip = data.skip || { en: 'Skip' };
    this.steps = data.steps || [];
    this.hideActions = data.hideActions;
    console.log(this.steps);
  }

  onStepStatusChanged (step: IChillzDialogStep, $event): void {
    console.log(step, $event);
    if ($event === 'saved') {
      this.setStep(this.currentStep + 1);
    }
  }

  setStep (i: number): void {
    this.currentStep = i;
    if (!this.steps[this.currentStep]) {
      this.dialogRef.close();
    } else {
      if (this.steps[this.currentStep].closeAllowed) {
        this.dialogRef.disableClose = false;
      }
    }
  }

  ngOnInit (): void {
    this.stepIndicatorSteps = this.steps?.map((x) => ({ label: x.stepIndicatorLabel || x.title }));
    this.setStep(0);
  }
}
